(function() {
    'use strict';
    vf['mph-hero-slider'] = {
        IMAGE_WIDTH_DESKTOP: 2560,
        IMAGE_HEIGHT_DESKTOP: 432,
        IMAGE_WIDTH_MOBILE: 599,
        IMAGE_HEIGHT_MOBIL: 730,
        MAX_MOBILE_WIDTH: 599,

        settings: {
            target: '.mod-mph-hero-slider',
            mySlider: null,
            slideCounter: 0

        },
        init: function(){
            vf['mph-hero-slider'].setupSlider();
        },


        /** specify slider attributes and setup transitionStart event listener */
        setupSlider: function (){



            var s = vf['mph-hero-slider'];


            var autoplay = 'true' === document.querySelector(this.settings.target).getAttribute('data-slider-autoplay') ? true : false;
            null === autoplay ? true : false;



            this.settings.mySlider = tns({
                container: '.mod-mph-hero-slider',
                items: 1,
                slideBy: 'page',
                controls: true,
                controlsText: [' ', ' '],
                arrowKeys: true,
                mouseDrag: false,
                speed: 500,
                lazyload: false,
                autoplay: autoplay,
                autoplayTimeout: 6800,
                autoplayHoverPause: true,
                autoplayButtonOutput: false
            });

            s.settings.mySlider.events.on('transitionStart', s.addColorControlCalsses);
            s.addColorControlCalsses();
            window.addEventListener('resize', s.addColorControlCalsses);

            this.setVisible();
        },

        /** */
        setVisible: function(){
            // debugger;
            var sliderContainer = document.querySelector('.hero-wrapper');
            sliderContainer.style.visibility = 'visible';

        },

        /** adds control skining classes */
        addColorControlCalsses: function (){
            var s = vf['mph-hero-slider'];

            var sliderContainer = document.querySelector(s.settings.target);
            var slides = sliderContainer.querySelectorAll('.mph-hero');
            var targetSlide;

            for (var i = 0; i < slides.length; i++){
                if (slides[i].classList.contains('tns-slide-active')){
                //if ('false' === slides[i].getAttribute('aria-hidden')){
                    targetSlide = slides[i];
                    break;
                }
            }

            var heroWrapper = document.querySelector('.hero-wrapper');
            if (targetSlide.classList.contains('cb-dark') ||
                targetSlide.classList.contains('cb-t-dark')

            ){
                heroWrapper.classList.remove('dark-controls');
                heroWrapper.classList.add('bright-controls');

            } else {
                heroWrapper.classList.remove('bright-controls');
                heroWrapper.classList.add('dark-controls');
            }


            if ( targetSlide.classList.contains('cb-t-dark-light')){
                if (window.innerWidth <= s.MAX_MOBILE_WIDTH){
                    heroWrapper.classList.remove('bright-controls');
                    heroWrapper.classList.add('dark-controls');
                } else {
                    heroWrapper.classList.remove('dark-controls');
                    heroWrapper.classList.add('bright-controls');
                }
            }

            if ( targetSlide.classList.contains('cb-t-light-dark')){
                if (window.innerWidth <= s.MAX_MOBILE_WIDTH){
                    heroWrapper.classList.remove('dark-controls');
                    heroWrapper.classList.add('bright-controls');
                } else {
                    heroWrapper.classList.remove('bright-controls');
                    heroWrapper.classList.add('dark-controls');
                }
            }
            s.settings.slideCounter = s.settings.mySlider.getInfo().navItems.length;
        },



        /** test function - TODO: Remove for production release */
        // addSlide: function (){
        //     vf['mph-hero-slider'].settings.slideCounter++;
        //     vf['mph-hero-slider'].settings.mySlider.destroy();
        //     $('.mod-mph-hero-slider').append('<div class="mph-hero cb-dark"><div class="bg-img"><picture><source srcset="/simplicity/components/mph/assets/hero/flex-hero-2560.jpg" media="(min-width: 600px)"><source srcset="/simplicity/components/mph/assets/hero/flex-hero-599.jpg" media="(max-width: 599px)"><img src="/simplicity/components/mph/assets/hero/flex-hero-2560.jpg" alt="alt" data-src="/simplicity/components/mph/assets/hero/flex-hero-2560.jpg" class="only-lrg"> <img src="/simplicity/components/mph/assets/hero/flex-hero-599.jpg" alt="alt" data-src="/simplicity/components/mph/assets/hero/flex-hero-599.jpg" class="only-sml" style=""><noscript><img src="/simplicity/components/mph/assets/hero/flex-hero-2560.jpg" alt="alt"></noscript></picture></div><div class="badge-box"></div><div class="belt cb-right-40"><div class="content-box"><div class="headline">NEW SLIDE ' + vf['mph-hero-slider'].settings.slideCounter + '</div><div class="subline"><p>Nur online: Spar jetzt 50% auf den Einmalpreis und bekomm in den ersten 12 Monaten einen Aktionsrabatt.</p></div><div class="btn-wrap"><div class="btn">Zum Angebot</div></div></div></div><div class="indicator-box"></div></div>');
        //     vf['mph-hero-slider'].setupSlider();
        // },

        // /** test function - TODO: Remove for production release */
        // removeSlide: function (){
        //     vf['mph-hero-slider'].settings.mySlider.destroy();
        //     $('.mod-mph-hero-slider .mph-hero').last().remove();
        //     vf['mph-hero-slider'].setupSlider();
        // }
    };
}(vf));

(function() {
    'use strict';
    vf['whisbi-btn-wrap'] = {
        settings: {
            target: '.mod-whisbi-btn-wrap',
            minUhrzeit: 800,
            maxUhrzeit: 2000,
            feiertage: '20170529/20170530',
        },

        init: function(context) {

            var s = this.settings;
            $(s.target, context).addBack(s.target).each(function() {
                var $this = $(this);

                var openVirtualShop = function(){

                    var isValidTime = function () {

                        var ret = false,
                            datum = new Date(),
                            stunde = datum.getHours(),
                            minute = datum.getMinutes(),
                            jahr = datum.getFullYear(),
                            monat = datum.getMonth() + 1,
                            tag = datum.getDate(),
                            datumStr = jahr.toString() + (9 < monat ? monat.toString() : '0' + monat) + (9 < tag ? tag.toString() : '0' + tag);

                        var uhrzeit = stunde * 100 + minute;

                        if (0 <= s.feiertage.indexOf(datumStr)) {
                            ret = false;
                        } else {
                            if (uhrzeit >= s.minUhrzeit && uhrzeit <= s.maxUhrzeit) {
                                var ret = true;
                            } else {
                                var ret = false;
                            }
                        }
                        return ret;
                    };

                    if (isValidTime()) {
                        $this.addClass('open');
                    }
                };

                openVirtualShop();

            });
        },
    };
}(vf));

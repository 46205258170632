(function () {//eslint-disable-line strict

    vf['accordion-module'] = {
        settings: {
            target: '.mod-accordion-module',
            accordionLinkArray: []
        },

        init: function () {
            $(document).ready(function () {

                // look for the hash
                var href = window.location.href;
                if (-1 < window.location.href.indexOf('#') && 0 < window.location.hash.length) {
                    var acID = (-1 < href.indexOf('#')) ? href.split('#')[1] : '';
                    acID = decodeURI(acID);
                    vf['accordion-module'].openAccordionAndScrollToSection(acID);
                }

                // go trough all tg-items and add the ids to the array
                $('.accordion .tg-item').each(function () {
                    var myNode = $(this);
                    var nodeID = myNode.attr('id');
                    if (undefined !== nodeID && '' !== nodeID) {
                        vf['accordion-module'].settings.accordionLinkArray.push(nodeID);
                    }
                });

                // not go trough all links and check for the ids
                $('a').each(function () {
                    var myNode = $(this);
                    myLink = myNode.attr('href');
                    var arr = vf['accordion-module'].settings.accordionLinkArray;
                    for (var i = 0; i < arr.length; i++) {
                        var testLink = '#' + arr[i];
                        if (testLink === myLink) {
                            myNode.addClass('accordion-id-link');
                        }
                    }
                });

                // intercetp all clicks
                $('.accordion-id-link').click(function (e) {
                    // capture the link clicked
                    var link = $(e.target).attr('href');
                    if ('undefined' === typeof link) {
                        return;
                    }
                    link = link.substr(1);
                    vf['accordion-module'].openAccordionAndScrollToSection(link);
                });
            });
        },

        //*helper method for above
        openAccordionAndScrollToSection: function (myID) {
            try {
                var selectorString = '#' + myID;
                var liElement = $(selectorString);
                // On page load open the accordion header
                if (0 < liElement.length) {
                    if (!liElement.children('a').hasClass('tg-active')) {
                        liElement.children('a').click();
                    }
                }
            } catch (e) {
                // fix ticket VFEO-6494 to avoid # in URL

            }
        }
    };
}(vf));

(function () {

    'use strict';

    var chessPattern = function () {

		// dekstop and tablet full, right, left, right, right, left, left
		// mobile full, right, left, right, left
        var layout = vf.util.layout();

        if ('sml' === layout) {

            $('.blogpost').each(function (index) {
                var $blogpost = $(this);

                if (true === $blogpost.hasClass('large')) {
                    return true;
                }

                if (1 === index % 2) {
                    $blogpost.removeClass('left');
                    $blogpost.addClass('right');
                }
                else {
                    $blogpost.removeClass('right');
                    $blogpost.addClass('left');
                }
                return true;


            });

        }
        else {

            var row = 'right';

            $('.blogpost').each(function (index) {
                var $blogpost = $(this);

                if (true === $blogpost.hasClass('large')) {
                    return true;
                }

                if (1 === index) {
                    $blogpost.removeClass('left');
                    $blogpost.addClass('right');
                    return true;
                }

                if (2 === index) {
                    $blogpost.removeClass('right');
                    $blogpost.addClass('left');
                    return true;
                }

                if ('right' === row) {
                    $blogpost.removeClass('left');
                    $blogpost.addClass('right');
                }
                else {
                    $blogpost.removeClass('right');
                    $blogpost.addClass('left');
                }

                if (0 === index % 2) {
                    row = ('right' === row) ? 'left' : 'right';
                }

                return true;

            });

        }

    };

    var setHeight = function ($blogposts) {

        if (undefined === $blogposts) {
            $blogposts = $('.blogpost');
        }

        $blogposts.each(function () {

            var $blogpost = $(this);

            var $head = $($blogpost.get(0)).find('.txt-wrap .date');

            var headHeight = $head.height() + parseInt($head.css('margin-bottom'), 10);

            var height = vf.blog.settings.width;

            if (true === $blogpost.hasClass('small')) {

                height = height / 2;
            }

            $blogpost.height(height);
            $blogpost.find('.img-wrap').height(height);
            $blogpost.find('.content-wrap, .txt-wrap').height(height - 40);

            $blogpost.find('.copytext').trigger('destroy.dot');
            $blogpost.find('.copytext').css('max-height', height - 40 - headHeight);
            $blogpost.find('.copytext').dotdotdot({
                watch: false
            });
        });

    };

    var getWidth = function () {
        vf.blog.settings.width = $('.blogpost').width();
    };

    var handleResize = function () {
        getWidth();
        setHeight();
        chessPattern();
    };

    vf.blog = {
        settings: {
            width: undefined
        },

        init: function () {

            getWidth();

            setHeight();

            chessPattern();

            $(window).on('vf::resize', handleResize);

            $('.show-more-blogposts .btn').click(function () {

                $('.show-more-blogposts').remove();
                $('.blogpost.hidden').css('display', 'block');
                setHeight($('.blogpost.hidden'));
                $('.blogpost.hidden').removeClass('hidden');
            });

        }

    };


	// to implement hover effect
    $('.blogpost').mouseover(
		function () {
    $(this).siblings('.blogpost').css('z-index', 2);
    $(this).css('z-index', 3);
}
	);








}(vf));

// Market configuration
var vf = vf ? vf : {}; // eslint-disable-line no-use-before-define
(function(){
    'use strict';
    vf.config = {
        string: {
            show: 'show ',
            hide: 'hide ',
            cont: 'content ',
            drop: 'drop-list ',
            basket: 'items in your ',
            menu: 'menu ',
            error: 'Error',
            page: 'page ',
            'close-modal': 'Close<span class="access"> modal</span>',
            'view-more': 'View all',
            'tg-content': 'content',
            'multi-select-first': 'Select {label}',
            charcount: '{int} Zeichen &uuml;brig.',
            compareFree: ' - {number} out of {total} compare slots free.',
            compareAdd: 'Add to compare',
            compareNow: 'Compare Now',
            compareAdded: 'Added to compare',
            compareTooltipSingle: '1 device is added to compare.',
            compareTooltip: '{number} devices are added to compare.<br><a href="#" class="link-body">Go to detailed comparison.</a>',
            play: 'Play video'
        },
        view: {
            search: '<div class="search-cat"><div class="h4">{heading}</div>{items}</div>',
            searchrow: '<li><a href="{targetUrl}" class="link-icon"><span class="icon-wrap"><img src="{icon}"" alt=""></i></span><span>{name}</span></a></li>',
            searchresults: '<div class="se-results"></div>'
        },
        form: {
            html: {
                eheading: 'Sorry there have been {{count}} errors:',
                error: '<div class="alert warning fm-error"><svg class="icon-warning-i-sml i-sml"><use xlink:href="/simplicity/svgdefs.svg#i-warning-sml" /></svg><div><h4><span class="access">Error: </span>{{message}}.</h4></div></div>',
                required: 'Bitte f&uuml;llen Sie das Feld aus'
            },

            patterns: {
                alpha: {
                    match: /^([A-Za-z-\s]+)$/,
                    error: 'Please ensure only text is entered'
                },
                numeric: {
                    match: /^\d+$/,
                    error: 'Please ensure only numbers are entered'
                },
                alphanumeric: {
                    match: /^([A-Za-z0-9]+)$/,
                    error: 'Please ensure only text and/or numbers are entered'
                },
                postcode: {
                    match: /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$/,
                    error: 'Please enter a valid UK postcode (e.g. AA1 2BB)'
                },
                date: {
                    match: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]((19|20)\d\d)$/,
                    error: 'Please enter a valid date (e.g. 28/11/2013)'
                },
                landline: {
                    match: /^([\+(][+0-9()]{1,5}([ )\-])?)?([\(]{1}[0-9]{3}[\)])?([0-9 \-]{1,256}([ \s\-])?)((x|ext|extension)?[0-9]{1,4}?)$/i,
                    error: 'Please enter a valid landline number (e.g. 020 1234 1234)'
                },
                mobile: {
                    match: /^((0|\+44|00\d{2})7(5|6|7|8|9){1}\d{2}\s?\d{6})$/,
                    error: 'Please enter a valid mobile number (e.g. 07555 000000)'
                },
                email: {
                    match: /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i,
                    error: 'Please enter a valid email address (e.g. johndoe@gmail.com)'
                },
                pswmatch: {
                    error: 'The passwords don\'t match'
                },
                onerequired: {
                    none: 'One of these fields has to be filled',
                    more: 'Just one of these fields has to be filled'
                },
                min20: {
                    match: /.{20,}$/,
                    error: 'Sie m&uuml;ssen mehr als 20 Zeichen eingeben'
                },
                min1: {
                    match: /.{1,}$/,
                    error: 'Sie m&uuml;ssen mindestens als 2 Zeichen eingeben'
                },
                min10: {
                    match: /.{10,}$/,
                    error: 'Bitte geben Sie mindestens als 10 Zeichen ein'
                }
            },

            functions: {
                checkUser: {
                    rule: function() {
                        var response = true,
                            target = this;
                        if (target.validate()) {
                            $.ajax({
                                type: 'POST',
                                url: '../../assets/json/response.json',
                                dataType: 'json',
                                async: false,
                                success: function (data) {
                                    response = data[0].username;
                                }
                            });
                        }
                        return 'false' !== response;
                    },
                    error: 'This username is not valid'
                },
                customCombine: {
                    rule: function() {
                        var target = this,
                            isCombine = (target.data('combine')) ? true : false,
                            combine = (isCombine) ? $(target.data('combine')) : $(target.data('required')),
                            response = true;


                        if (isCombine) {
                            if ('' !== combine.val() && combine.validate() && '' === target.val()) {
                                target.find(vf.form.settings.error).add(target.prev(vf.form.settings.error)).add(target.closest(vf.form.settings.data).prev(vf.form.settings.error)).remove();
                                vf.form.error.apply(target, [vf.config.form.functions.customCombine.error]);
                            } else {
                                combine.find(vf.form.settings.errorborder).addBack(vf.form.settings.errorborder).removeClass(vf.form.settings.errorborder.substring(1));
                                combine.find(vf.form.settings.error).add(combine.prev(vf.form.settings.error)).add(combine.closest(vf.form.settings.data).prev(vf.form.settings.error)).remove();
                            }
                        } else {
                            if ('' !== target.val() && target.validate() && '' === combine.val()) {
                                combine.find(vf.form.settings.error).add(combine.prev(vf.form.settings.error)).add(combine.closest(vf.form.settings.data).prev(vf.form.settings.error)).remove();
                                vf.form.error.apply(combine, [vf.config.form.functions.customCombine.error]);
                            } else {
                                combine.find(vf.form.settings.errorborder).addBack(vf.form.settings.errorborder).removeClass(vf.form.settings.errorborder.substring(1));
                                combine.find(vf.form.settings.error).add(combine.prev(vf.form.settings.error)).add(combine.closest(vf.form.settings.data).prev(vf.form.settings.error)).remove();
                            }
                        }

                        return response;
                    },
                    error: 'This field is required'
                }
            }

        },
        worldmanual: {
            troubleshooting: {
                startStrg: '<h4>Start the troubleshooting guide.</h4>' +
                            '<p>The troubleshooting guide will walk you through a number of possible causes and solutions</p>',
                Start: 'Start',
                yes: 'Ja',
                no: 'Nein',
                resolvedUrl: '../../support/assets/world-manual/Troubleshooting/GetDeviceTroubleshootingSolvedText.xml',
                unresolvedUrl: '../../support/assets/world-manual/Troubleshooting/GetDeviceTroubleshootingNotSolvedText.xml'
            },
            articles: {
                noImage: 'There is no image for this step. Select the arrow button to continue.'
            }
        },

        ajaxLoad: '<div class="tg-progress"><div><img src="/simplicity/assets/img/loader-lrg.gif" alt="Loading content" aria-busy="true"><div>Loading...<span class="access"> content</span></div>'

    };
}(vf));

// Validate function for forms
(function ($){
    'use strict';
    var methods = {

        settings: {
            required: '.fm-required',
            checked: ':checked, .checkbox',
            pattern: '[data-pattern]',
            pswmatch: '.fm-pswmatch',
            onerequired: '.fm-onerequired'
        },

        match: function() {
            var target = this,
                returnValue = true;

            target.find('input').addBack('input').each(function() {
                var input = $(this),
                    pattern = vf.config.form.patterns[input.data('pattern')],
                    match = input.val().match(pattern.match);

                if (!match) {
                    returnValue = false;
                }
            });
            return returnValue;
        },

        matchPassword: function(input) {
            var password = $(input.data('match')),
                pswmatch = input.val();

            if (password.val() !== pswmatch) {
                return false;
            } else {
                return true;
            }
        }
    };

    $.fn.validate = function() {
        var target = $(this),
            s = methods.settings,
            inputs = s.required + ',' + s.pattern + ',' + s.pswmatch + ',' + s.onerequired,
            returnValue = true;

        $.unique(target.find(inputs).addBack(inputs)).each(function() {
            var input = $(this);

            if (input.has('[type=radio]')[0]) {

                if (!input.has(s.checked)[0]) {returnValue = false;}

            } else {

                if (input.is(s.required)) {

                    if (input.has(':unfilled')[0] || input.is(':unfilled') || !input.is(s.checked) && input.is('[type=checkbox]')) {

                        returnValue = false;

                    } else if (input.has(s.pattern)[0] || input.is(s.pattern)) {returnValue = $.proxy(methods.match, input)();}

                } else if (input.has(':unfilled')[0] || false === input.is(':unfilled')) {returnValue = $.proxy(methods.match, input)();}

                if (input.is(s.pswmatch)) {
                    returnValue = methods.matchPassword(input);
                }
            }
        });

        return returnValue;
    };

})(jQuery);

(function () {

    /**
     * usage
     * <div class="mod mod-eq-height" data-eq-height=".foo, .bar">
     * Make all children of .mod-eq-height with class .foo equal height and all .bar, too.
     * The heightest .bar sets height for all .bar
     *
     * <div class="mod mod-eq-height" data-eq-height=".foo, .bar" data-eq-height-viewport="tablet, desktop">
     * Sets eq height only for tablet and desktop viewport
     *
     * <div class="mod mod-eq-height" data-eq-height=".foo, .bar" data-eq-height-viewport="dekstop">
     * Sets eq height only for desktop viewport
     *
     * <div class="mod mod-eq-height" data-eq-height=".foo, .bar" data-force-eq-height="true">
     * Legacy notation for
     * <div class="mod mod-eq-height" data-eq-height=".foo, .bar" data-eq-height-viewport="mobile, tablet, desktop">
     */

    var splitAndTrim = function(string, delimiter){
        var array = string.split(delimiter);

        $(array).each(function(index){
            array[index] = $.trim(this);
        });

        return array;

    };

    var resetHeight = function($parent){

        $parent.find('.eq-height').each(function(){
            $(this).css('height', '');
            $(this).removeClass('eq-height');
        });

    };

    var makeEqHeight = function($parent){

        var selectors = $parent.attr('data-eq-height');

        if (undefined === selectors){
            return;
        }

        var viewports = $parent.attr('data-eq-height-viewport');

        if (true === $(this).data('force-eq-height')){
            viewports = 'mobile, tablet, desktop';
        }

        if (undefined === viewports){
            // no viewport defined, use legacy viewport lrg a.k.a. tablet and desktop
            viewports = 'tablet, desktop';
        }

        viewports = splitAndTrim(viewports, ',');

        var viewport = vf.util.layout(true);

        if (-1 === $.inArray(viewport, viewports)){
            resetHeight($parent);
            return;
        }


        selectors = splitAndTrim(selectors, ',');

        $(selectors).each(function(index, selector){
            var $elems = $parent.find(selector);

            var maxHeight = 0;

            $elems.css('height', '');

            $elems.each(function(){

                if (maxHeight < $(this).outerHeight()){
                    maxHeight = $(this).outerHeight();
                }

            });

            $elems.height(maxHeight).addClass('eq-height');

        });

    };

    var parentsOfEqHeight = function(evt){

        var $eqHeightParents = $(vf['eq-height'].settings.target);

        $eqHeightParents.each(function(){

            if (undefined === $(this).attr('data-eq-height')){
                return true;
            }

            var preventInit = $(this).attr('data-eq-height-prevent-init');

            if ('true' === preventInit){

                var $eqHeight = $(this);

                // only register custom event once
                if (undefined === $eqHeight.attr('data-eq-height-by-event')){

                    $eqHeight.attr('data-eq-height-by-event', true);

                    $eqHeight.on('eq-height:update', function(){

                        // remove data-eq-height-prevent-init -> After the very first update by custom event, equal height will be triggerd "normal", too
                        // e.g. window@resize
                        $(this).removeAttr('data-eq-height-prevent-init');

                        makeEqHeight($(this));

                    });

                }

                return true;
            }

            makeEqHeight($(this));
        });

    };

    // Setting Jump Marks for CTA links by action-box module
    var setJumpMarks = function(evt){
        var actionBoxCTAs = $('.action-box a');
        if (0 < actionBoxCTAs.length) {
            actionBoxCTAs.each(function(){
                var jumpmark = $( this ).data( 'jumpmark' );
                if (undefined !== jumpmark && 0 < jumpmark.length && 'no-data' !== jumpmark) {
                    var currentHref = $( this ).attr('href').trim();
                    var href = null;
                    if (0 === currentHref.indexOf('#')) {
                        href = '#' + jumpmark;
                    } else {
                        var hashPos = currentHref.indexOf('#');
                        if (-1 !== hashPos) {
                            currentHref = currentHref.substr(0, hashPos);
                        }
                        href = currentHref + '#' + jumpmark;
                    }
                    $( this ).attr('href', href);
                }
            });
        }
    };

    var init = function(){

        $(window).on('vf::resize vf::lazyloaded orientationchange vf::fontLoaded', parentsOfEqHeight);
        parentsOfEqHeight();
        setJumpMarks();

    };

    vf['eq-height'] = {
        settings: {
            target: '.mod-eq-height'
        },

        init: init
    };

}(vf));

(function () {
    'use strict';

    vf['glnav-footer'] = {
        settings: {
            target: '.mod-glnav-footer',
            savedViewPortWasDesktop: false
        },
        /**
         * init function
         */
        init: function (context) {
            var s = vf['glnav-footer'].settings;

            $(s.target, context).addBack(s.target).each(function () {
                // arrow button click
                $(s.target).find('.head,.chevron').click(function () {
                    if (vf.glnav.isTabletOrMobile()){
                        $(this).parent().find('.foo-secondary').animate({ height: 'toggle' }, 500);
                        $(this).parent().toggleClass('item-main-active');
                    }
                });

                $(window).bind('resize', function () {

                    if (!vf.glnav.isTabletOrMobile()){
                        $('.mod-glnav-footer .foo-secondary').css('display', 'block');
                        $('.mod-glnav-footer .foo-item-main').removeClass('item-main-active');
                    } else {
                        if (vf['glnav-footer'].settings.savedViewPortWasDesktop){
                            $('.mod-glnav-footer .foo-secondary').css('display', 'none');
                            $('.mod-glnav-footer .foo-item-main').removeClass('item-main-active');
                        }
                    }

                    vf['glnav-footer'].settings.savedViewPortWasDesktop = !vf.glnav.isTabletOrMobile();
                });
            });

        }

    };
}(vf));

(function () {//eslint-disable-line strict
    vf['image-text'] = {
        aImageTextModels: null,
        iTabletBreakpoint: 600,
        iDesktopBreakpoint: 1025,
        sShaddowWhite: '0px 4px 15px 0px rgba(0,0,0, 0.4)',
        sShaddowRed: '0px 2px 5px 0px rgba(0,0,0,, 0.7)',
        sShaddowImage: '0 8px 25px 0 rgba(0,0,0, 0.35)',
        sShaddowInteraction: '0 16px 35px 0 rgba(0,0,0,, 0.25)',

        init: function () {
            // find all elements that implement the mod-image-text
            var aAllImageTextDivs = $('.mod-image-text');

            // create the array holding the models
            this.aImageTextModels = [];
            // create the image text modles
            for (var i = 0; i < aAllImageTextDivs.length; i++) {
                this.aImageTextModels.push(this.createImageTextModel(aAllImageTextDivs[i]));
            }

            var that = this;

            $(window).on('resize vf::accordionItemToggle vf::tabsSwitch', function () {
                that.drawHandler();
            });

            $('.image-text .img-wrap img').load(function () {
                that.imageLoadHandler(this);
            });

            $(document).ready(function () {
                that.drawHandler();
            });

            $(window).load(function() {
                setTimeout(function() {
                    that.drawHandler();
                }, 200);
            });



            that.drawHandler();

        },
        createImageTextModel: function (oDiv) {
            var oModel = {};
            oModel.oDiv = oDiv;
            oModel.imgWrap = $(oDiv).find('.img-wrap').get(0);
            oModel.contentWrap = $(oDiv).find('.content-wrap').get(0);
            oModel.img = $(oDiv).find('.img-wrap .only-lrg').get(0);
            oModel.redBoxMobile = null;
            oModel.redBoxDesktop = null;
            return oModel;
        },
        drawHandler: function () {

            if (window.innerWidth < this.iTabletBreakpoint) {
                for (var i = 0; i < this.aImageTextModels.length; i++) {
                    this.drawMobile(this.aImageTextModels[i]);
                }
            } else {
                for (var i = 0; i < this.aImageTextModels.length; i++) {
                    this.drawTabletDesktop(this.aImageTextModels[i]);
                }
            }
        },
        drawMobile: function (oModel) {

            var isLowerLevel = false;
            if ($(oModel.contentWrap).parents('.professional-article').length) {
                isLowerLevel = true;
            }
            if (null === oModel.redBoxMobile) {
                var oRedBox = document.createElement('div');
                oRedBox.className = 'red-box-mobile';
                oModel.oDiv.parentNode.insertBefore(oRedBox, oModel.oDiv.nextSibling);
                oModel.redBoxMobile = oRedBox;
            }
            if (null !== oModel.redBoxMobile && false === isLowerLevel) {
                oModel.redBoxMobile.style.display = 'inline-block';
            }
            if (null !== oModel.redBoxDesktop) {
                oModel.redBoxDesktop.style.display = 'none';
            }

            oModel.oDiv.style.backgroundColor = 'white';
            if (false === isLowerLevel) {
                oModel.oDiv.style.boxShadow = this.sShaddowWhite;
            }
            oModel.oDiv.style.marginTop = '20px';
            oModel.imgWrap.style.marginTop = '-20px';
            oModel.imgWrap.style.boxShadow = 'none';
            oModel.contentWrap.style.boxShadow = 'none';
            if (false === isLowerLevel) {
                oModel.contentWrap.style.padding = '0 20px 0 20px';
            }
            oModel.contentWrap.style.marginTop = '0px';
            oModel.contentWrap.style.height = 'auto';
            oModel.oDiv.style.marginBottom = '15px';
        },
        drawTabletDesktop: function (oModel) {

            var isLowerLevel = false;
            if ($(oModel.contentWrap).parents('.professional-article').length) {
                isLowerLevel = true;
            }
            if (null === oModel.redBoxDesktop) {
                var oRedBox = document.createElement('div');
                oRedBox.className = 'red-box-dektop col';
                oModel.oDiv.appendChild(oRedBox);
                oModel.redBoxDesktop = oRedBox;
            }

            if (null !== oModel.redBoxDesktop && false === isLowerLevel) {
                oModel.redBoxDesktop.style.display = 'inline';
            }
            if (null !== oModel.redBoxMobile) {
                oModel.redBoxMobile.style.display = 'none';
            }

            oModel.oDiv.style.backgroundColor = 'transparent';

            oModel.oDiv.style.boxShadow = 'none';
            oModel.oDiv.style.marginTop = '0px';
            oModel.imgWrap.style.marginTop = '0px';
            if (false === isLowerLevel) {
                oModel.contentWrap.style.boxShadow = this.sShaddowWhite;
                oModel.img.style.boxShadow = this.sShaddowImage;
            }

            var innerHeight = 0;
            for (var i = 0; i < oModel.contentWrap.childNodes.length; i++){
                if (oModel.contentWrap.childNodes[i].clientHeight !== undefined){
                    innerHeight += oModel.contentWrap.childNodes[i].clientHeight;
                }
            }

            var iwrapperHeight;

            // look for ts switch
            // the padding needs to be adjusted depending on the viewport (tablet or desktop)
            // only for the content inside

            if (oModel.oDiv.classList.contains('ts-switch2')) {
                if (window.innerWidth < this.iDesktopBreakpoint) {
                    oModel.contentWrap.style.padding = '0 70px 0 30px';
                } else {
                    oModel.contentWrap.style.padding = '0 80px 0 40px';
                }
            } else {
                if (window.innerWidth < this.iDesktopBreakpoint) {
                    oModel.contentWrap.style.padding = '0 30px 0 70px';
                } else {
                    oModel.contentWrap.style.padding = '0 40px 0 80px';
                }
            }


            // in case the height image hight is smaller than the content height
            if (oModel.img.height - 120 > innerHeight) {
                oModel.contentWrap.style.marginTop = '40px';
                iwrapperHeight = oModel.img.height - 80;
                oModel.contentWrap.style.height = iwrapperHeight + 'px';

                oModel.redBoxDesktop.style.marginBottom = '-20px';

                oModel.oDiv.style.marginTop = '0px';
                if (false === isLowerLevel) {
                    oModel.oDiv.style.marginBottom = '40px';
                }
                oModel.contentWrap.style.zIndex = '1';
                oModel.imgWrap.style.zIndex = '2';
            } else { // content overlapping the image
                if (innerHeight < oModel.img.height) {
                    iwrapperHeight = oModel.img.height + 80;
                } else {
                    iwrapperHeight = innerHeight + 60;
                }

                oModel.contentWrap.style.marginTop = '-40px';
                oModel.contentWrap.style.height = iwrapperHeight + 'px';
                oModel.redBoxDesktop.style.marginBottom = '-40px';
                oModel.oDiv.style.marginTop = '40px';
                if (false === isLowerLevel) {
                    oModel.oDiv.style.marginBottom = '60px';
                }
            }
            iwrapperHeight = $(oModel.oDiv).find('.img-wrap > img.only-lrg').height() - 80;

            var margin = iwrapperHeight / 2 - innerHeight / 2;
            if (30 > margin) { margin = 30; }
            oModel.contentWrap.style.paddingTop = margin + 'px';

        },
        imageLoadHandler: function (oImage) {
            if (oImage.classList.contains('only-lrg')) {
                this.drawHandler();
            }
        },

    };

    // jump mark
    var ctas = $('.image-text').find('a');
    ctas.each(function() {
        var jumpmark = $( this ).data( 'jumpmark' );
        if (undefined !== jumpmark && 0 < jumpmark.length && 'no-data' !== jumpmark) {
            var currentHref = $( this ).attr('href').trim();
            var href = null;
            if (0 === currentHref.indexOf('#')) {
                href = '#' + jumpmark;
            } else {
                var hashPos = currentHref.indexOf('#');
                if (-1 !== hashPos) {
                    currentHref = currentHref.substr(0, hashPos);
                }
                href = currentHref + '#' + jumpmark;
            }
            $( this ).attr('href', href);
        }
    });

}(vf));

(function () {
    'use strict';
    vf['browser-detection'] = {
        settings: {
            target: '.mod-browser-detection'
        },

        init: function () {
            // var detection = this;
            this.doBrowserCheck();

			/*
			var oTestDiv = $("#testDiv");
			oTestDiv.click(function (){
				var closeButton =  $(".al-close");
 	 			closeButton.unbind();

				detection.doBrowserCheck();
			});
			*/
        },

        doBrowserCheck: function () {
            var detection = this;
            var oTestResult = { bOldBrowser: false, bCookiesEnabled: true };
            var oBrowser = detection.getBrowserInfo();

            switch (oBrowser.name) {
				// check for old IE
            case 'MSIE':
                if (9 > oBrowser.version) {
                    oTestResult.bOldBrowser = true;
                }
                break;

				// check for old Chrome
            case 'Chrome':
                if (10 > oBrowser.version) {
                    oTestResult.bOldBrowser = true;
                }
                break;


				// check for old Firefox
            case 'Firefox':
                if (11 > oBrowser.version) {
                    oTestResult.bOldBrowser = true;
                }
                break;

				// check for old Opera
            case 'Opera':
                if (12 > oBrowser.version) {
                    oTestResult.bOldBrowser = true;
                }
                break;
            }

			// check for cookies enabled
            var bCookiesEnabled = navigator.cookieEnabled;

            if (!bCookiesEnabled) {
                oTestResult.bCookiesEnabled = false;
            }

			// show the results
            if (oTestResult.bOldBrowser) {
                detection.showAlertMessage('ALERT!!!', 'You have an old browser');
            }
            else if (!oTestResult.bCookiesEnabled) {
                detection.showAlertMessage('ALERT!!!', 'you need to enable cookies!!');
            }
            else {
                detection.showAlertMessage('ALL GOOD :-)', '...your browser is capable to display our content');
            }
        },

		/*
		* the actual browser detection
		*/
        getBrowserInfo: function () {
            var ua = navigator.userAgent;
            var tem;
            var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return { name: 'IE', version: (tem[1] || '') };
            }
            if ('Chrome' === M[1]) {
                tem = ua.match(/\bOPR\/(\d+)/);
                if (null !== tem) { return { name: 'Opera', version: tem[1] }; }
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if (null !== (tem = ua.match(/version\/(\d+)/i))) { M.splice(1, 1, tem[1]); }
            return {
                name: M[0],
                version: M[1]
            };
        },

		/*
		* the display part
		*/
        showAlertMessage: function (sAlertHeder, sAlertMessage) {

            var oAlertDiv = $('.mod-alert');

            var oTextDiv = $('#alertText');

            var sHtmlString = '<h4>' + sAlertHeder + '</h4><p>' + sAlertMessage + '</p>';
            oTextDiv.html(sHtmlString);

			//var closeButton =  $("#browserDetectCloseButton");
            var closeButton = $('.al-close');
            closeButton.unbind('click');
			//closeButton.unbind("click", vf.alert.close);

			//closeButton.unbind();

			//console.log(closeButton);

            //console.log('unbind');

            closeButton.click(function () { oAlertDiv.hide(300); });


            closeButton.style = 'textAlign:\'right\'';

			//
            oAlertDiv.show(600);

        }

    };
}(vf));

//vf['browser-detection'].init(null);

(function() {

    'use strict';

    var settings = {
        target: '.mod-roaming-stepper',
        callyaRoaming: '#roaming-stepper-callya-roaming',
        internationalCalling: '#roaming-stepper-international-calling'
    };

    var contractMode = $(settings.target).data('contract');

    var getCallyaRowLine = function(line){
        var results = {};
        var lines = $(settings.callyaRoaming + ' table tr:nth-child(' + line + ') td');
        lines.each(function(key, value){
            var lineValue = $(value).text();
            if ('' !== lineValue.trim()) {
                results[key] = lineValue.trim();
            }
        });
        return results;
    };

    var getCallyaColLine = function(line){
        var results = {};
        var lines = $(settings.callyaRoaming + ' table tr td:nth-child(' + line + ')');
        lines.each(function(key, value){
            var lineValue = $(value).text();
            if ('' !== lineValue.trim()) {
                results[key] = lineValue.trim();
            }
        });
        return results;
    };

    var getCallingRowLine = function(line){
        var results = {};
        var lines = $(settings.internationalCalling + ' table tr:nth-child(' + line + ') td');
        lines.each(function(key, value){
            var lineValue = $(value).text();
            if ('' !== lineValue.trim()) {
                results[key] = lineValue.trim();
            }
        });
        return results;
    };

    var getCallingColLine = function(line){
        var results = {};
        var lines = $(settings.internationalCalling + ' table tr td:nth-child(' + line + ')');
        lines.each(function(key, value){
            var lineValue = $(value).text();
            if ('' !== lineValue.trim()) {
                results[key] = lineValue.trim();
            }
        });
        return results;
    };

    var getCountryList = function(destination){
        var countryRows = getRawCountryList(destination);
        var result = { countries: [] };

        for (var item in countryRows) {
            result.countries.push({name: item, code: ''});
        }

        return result.countries;
    };

    var getRawCountryList = function(destination){

        if ('inForeignCountries' === destination) {
            var countryRows = getCallyaColLine(2);

            var result = { countries: [] };
            $.each(countryRows, function(key, value){
                // ignore the headlines
                if (2 < key && false === value in result.countries) {
                    result.countries[$.trim(value)] = {};
                }
            });
        } else if ('toForeignCountries' === destination) {
            var countryRows = getCallingColLine(2);
            var result = { countries: [] };
            $.each(countryRows, function(key, value){
                // ignore the headlines
                if (2 < key && false === value in result.countries) {
                    result.countries[$.trim(value)] = {};
                }
            });
        } else {
            var countryRows = getCallyaColLine(2);

            var result = { countries: [] };
            $.each(countryRows, function(key, value){
                // ignore the headlines
                if (2 < key && false === value in result.countries) {
                    result.countries[$.trim(value)] = {};
                }
            });

            countryRows = getCallingColLine(2);

            $.each(countryRows, function(key, value){
                // ignore the headlines
                if (2 < key && false === value in result.countries) {
                    result.countries[$.trim(value)] = {};
                }
            });
        }

        return result.countries;
    };

    var isNotFreeEU = function(obj){
        if (undefined !== obj.TableHeadline && 0 <= obj.TableHeadline.indexOf('CallYa EU-Roaming')) {
            return false;
        }
        return true;
    };

    var getButtonHTML = function(text, color, link){
        return '<a href="' + link + '" target="_blank" class="btn btn-sml btn-' + color + '">' + text + '</a>';
    };

    var getFootnoteHTML = function(text) {
        return '<div class="mod mod-footnotes footnotes">' +
            '<div class="accordion">' +
                '<ul class="mod mod-toggle tg-accord">' +
                    '<li class="tg-item">' +
                        '<a href="#" class="tg-head tg-active" aria-expanded="true" id="aria-110360413400">' +
                            '<svg class="i-xsml tg-hide"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/simplicity/svgdefs.svg#i-arrow-up-sml"></use></svg>' +
                            '<svg class="i-xsml tg-show"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/simplicity/svgdefs.svg#i-arrow-down-sml"></use></svg>' +
                        '</a>' +
                        '<div class="tg-body tg-display" aria-labelledby="aria-110360413400" role="region">' +
                            '<div class="tg-board">' +
                                '<ol>' +
                                    '<li>' + text + '</li>' +
                                '</ol>' +
                            '</div>' +
                        '</div>' +
                    '</li>' +
                '</ul>' +
            '</div>' +
        '</div>';
    };

    var getTableHTML = function(valueObject){
        var tableHeadline = valueObject.tableHeadline !== undefined ? valueObject.tableHeadline : '';
        var outgoingMobile = valueObject.outgoingMobile !== undefined ? valueObject.outgoingMobile + '/Min' : '';
        var outgoingHome = valueObject.outgoingHome !== undefined ? valueObject.outgoingHome + '/Min' : '';
        var incomingMobile = valueObject.incomingMobile !== undefined ? valueObject.incomingMobile + '/Min' : '';
        var incomingHome = valueObject.incomingHome !== undefined ? valueObject.incomingHome + '/Min' : '';
        var sms = valueObject.sms !== undefined ? valueObject.sms + '/SMS' : '';
        var mms = valueObject.mms !== undefined ? valueObject.mms + '/MMS' : '';
        var data = valueObject.data !== undefined ? valueObject.data + '/MB' : '';

        return '<div class="mod mod-responsive-table">' +
            '<div class="mod-responsive-table-options">' +
                '<div data-name="type" data-value="stacked" data-type="string"></div>' +
                '<div data-name="width" data-value="full" data-type="string"></div>' +
                '<div data-name="first-col-fixed" data-value="false" data-type="boolean"></div>' +
                '<div data-name="col-width-mode" data-value="auto" data-type="string"></div>' +
                '<div data-col-index="0" data-col-width="auto" data-type="string"></div>' +
                '<div data-col-index="1" data-col-width="auto" data-type="string"></div>' +
                '<div data-col-index="2" data-col-width="auto" data-type="string"></div>' +
                '<div data-col-index="3" data-col-width="auto" data-type="string"></div>' +
                '<div data-col-index="4" data-col-width="auto" data-type="string"></div>' +
                '<div data-name="first-line-headers" data-value="true" data-type="boolean"></div>' +
                '<div data-name="create-sub-headlines" data-value="true" data-type="boolean"></div>' +
                '<div data-name="group-by-first-col" data-value="false" data-type="boolean"></div>' +
                '<div data-name="join-rows-in-group" data-value="false" data-type="array"></div>' +
                '<div data-name="join-adjacent-cols" data-value="false" data-type="boolean"></div>' +
                '<div data-name="replace-with-icons" data-value="false" data-type="boolean"></div>' +
                '<div data-name="zebra" data-value="false" data-type="boolean"></div>' +
            '</div>' +
            '<table>' +
                '<tbody>' +
                    '<tr>' +
                        '<td>' + tableHeadline + '</td>' +
                        '<td></td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td>Abgehende Anrufe Mobil (nach DE und in andere EU-Länder)</td>' +
                        '<td>' + outgoingMobile + '</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td>Abgehende Anrufe Festnetz (nach DE und in andere EU-Länder)</td>' +
                        '<td>' + outgoingHome + '</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td>Ankommende Anrufe Mobil</td>' +
                        '<td>' + incomingMobile + '</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td>Ankommende Anrufe Festnetz</td>' +
                        '<td>' + incomingHome + '</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td>SMS (nach DE und in andere EU-Länder)</td>' +
                        '<td>' + sms + '</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td>MMS (nach DE und in andere EU-Länder)</td>' +
                        '<td>' + mms + '</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td>Daten</td>' +
                        '<td>' + data + '</td>' +
                    '</tr>' +
                 '<tbody>' +
            '</table>' +
            '<p>Preise inkl. MwSt.</p>' +
        '</div>' +
        '<div class="divide divide_sml divide_hidden">' +
            '<div></div>' +
        '</div>';
    };

    var getTextOnlyHTML = function(h3, copy){
        var html = '<div class="text-only">' +
            '<h3>' + h3 + '</h3>' +
            '<p>' + copy + '</p>';
        html += '</div>' +
        '<div class="divide divide_sml divide_hidden">' +
            '<div></div>' +
        '</div>';

        return html;
    };

    var setInternationalCallingStructure = function(){
        var results = {toForeignCountries: {}};

        //var countryRows = getCallingColLine(2);
        var allRows = $(settings.internationalCalling + ' table tr');

        $.each(allRows, function(key){
            // ignore the headlines
            if (2 < key) {
                var lineValue = getCallingRowLine(key);
                if (undefined !== lineValue[1]) {
                    if (!(lineValue[1] in results.toForeignCountries)) {
                        results.toForeignCountries[lineValue[1]] = {};
                    }
                    results.toForeignCountries[lineValue[1]][lineValue[2]] = {
                        EURoamingText: lineValue[3] !== undefined ? $.trim(lineValue[3]) : lineValue[3],
                        TableHeadline: lineValue[4] !== undefined ? $.trim(lineValue[4]) : lineValue[4],
                        OutgoingCallMobile: lineValue[5] !== undefined ? $.trim(lineValue[5]) : lineValue[5],
                        OutgoingCallHome: lineValue[6] !== undefined ? $.trim(lineValue[6]) : lineValue[6],
                        IncomingCallMobile: lineValue[7] !== undefined ? $.trim(lineValue[7]) : lineValue[7],
                        IncomingCallHome: lineValue[8] !== undefined ? $.trim(lineValue[8]) : lineValue[8],
                        Data: lineValue[11] !== undefined ? $.trim(lineValue[11]) : lineValue[11],
                        SMS: lineValue[9] !== undefined ? $.trim(lineValue[9]) : lineValue[9],
                        MMS: lineValue[10] !== undefined ? $.trim(lineValue[10]) : lineValue[10],
                        UpsellTariff: lineValue[12] !== undefined ? $.trim(lineValue[12]) : lineValue[12],
                        Footnote: lineValue[13] !== undefined ? $.trim(lineValue[13]) : lineValue[13],
                        Link: lineValue[14] !== undefined ? $.trim(lineValue[14]) : lineValue[14]
                    };
                }
            }
        });
        prepaid.toForeignCountries = results.toForeignCountries;
    };

    var setCallyaRoamingStructure = function(){
        var results = {inForeignCountries: {} };

        //var countryRows = getCallyaColLine(2);
        var allRows = $(settings.callyaRoaming + ' table tr');

        $.each(allRows, function(key){
            // ignore the headlines
            if (2 < key) {
                var lineValue = getCallyaRowLine(key);
                if (undefined !== lineValue[1]) {
                    if (!(lineValue[1] in results.inForeignCountries)) {
                        results.inForeignCountries[lineValue[1]] = {};
                    }
                    var button = lineValue[13] !== undefined ? $.trim(lineValue[13]) : lineValue[13];
                    var buttonColor = 'em'; // grey button
                    if (undefined !== button && 0 <= button.indexOf('lila')) {
                        buttonColor = 'purple'; // purple button
                    }
                    var buttonText = 'Mehr Infos';
                    if (undefined !== button && 0 <= button.indexOf('Jetzt Tarifwechsel')) {
                        buttonText = 'Jetzt Tarifwechsel durchführen';
                    }
                    results.inForeignCountries[lineValue[1]][lineValue[2]] = {
                        EURoamingText: lineValue[3] !== undefined ? $.trim(lineValue[3]) : lineValue[3],
                        TableHeadline: lineValue[4] !== undefined ? $.trim(lineValue[4]) : lineValue[4],
                        OutgoingCallMobile: lineValue[5] !== undefined ? $.trim(lineValue[5]) : lineValue[5],
                        OutgoingCallHome: lineValue[6] !== undefined ? $.trim(lineValue[6]) : lineValue[6],
                        IncomingCallMobile: lineValue[7] !== undefined ? $.trim(lineValue[7]) : lineValue[7],
                        IncomingCallHome: lineValue[8] !== undefined ? $.trim(lineValue[8]) : lineValue[8],
                        Data: lineValue[9] !== undefined ? $.trim(lineValue[9]) : lineValue[9],
                        SMS: lineValue[10] !== undefined ? $.trim(lineValue[10]) : lineValue[10],
                        MMS: lineValue[11] !== undefined ? $.trim(lineValue[11]) : lineValue[11],
                        UpsellTariff: lineValue[12] !== undefined ? $.trim(lineValue[12]) : lineValue[12],
                        ButtonText: buttonText,
                        ButtonColor: buttonColor,
                        Link: lineValue[14] !== undefined ? $.trim(lineValue[14]) : lineValue[14]
                    };
                }
            }
        });
        prepaid.inForeignCountries = results.inForeignCountries;
    };

    var prepaid = {inForeignCountries: {}, toForeignCountries: {}};

    setCallyaRoamingStructure();
    setInternationalCallingStructure();

    /**
     *** The offer flow in a static format use select as below
     *** offerFlow.toForeignCountries.Schweiz.other.option
     **/
    if ('prepaid' !== contractMode) {

        var countryListEurope = [
            'Belgien',
            'Bulgarien',
            'D\u00E4nemark',
            'Estland',
            'Finnland',
            'Frankreich',
            'Franz\u00F6sisch Guayana',
            'Franz\u00F6sisch Westindische Inseln',
            'Gibraltar',
            'Griechenland',
            'Gro\u00DFbritannien',
            'Guadeloupe',
            'Irland',
            'Island',
            'Italien',
            'Kroatien',
            'La Reunion',
            'Lettland',
            'Liechtenstein',
            'Litauen',
            'Luxemburg',
            'Malta',
            'Martinique',
            'Niederlande',
            'Norwegen',
            '\u00D6sterreich',
            'Polen',
            'Portugal',
            'Rum\u00E4nien',
            'Schweden',
            'Slowakei',
            'Slowenien',
            'Spanien',
            'Tschechien',
            'Ungarn',
            'Zypern'
        ];

        var countryListTruschcan = [
            'Andorra',
            'F\u00E4r\u00F6er Inseln',
            'Guernsey (GB)',
            'Isle of Man (GB)',
            'Jersey (GB)',
            'Kanada',
            'Monaco',
            'Schweiz',
            'T\u00FCrkei',
            'USA'
        ];

        var countryListRow = [
            'Afghanistan',
            '\u00C4gypten',
            'Albanien',
            'Algerien',
            'Antigua \u0026 Barbuda',
            'Argentinien',
            'Armenien',
            'Aruba',
            'Aserbaidschan',
            'Australien',
            'Bahrain',
            'Bangladesch',
            'Barbados',
            'Bermuda',
            'Bosnien-Herzegowina',
            'Brasilien',
            'Britische Jungferninseln',
            'Brunei',
            'Cayman Inseln',
            'Chile',
            'China',
            'Costa Rica',
            'Curacao (Niederl\u00E4ndische Antillen)',
            'Demokratische Republik Kongo',
            'Dominikanische Republik',
            'Ecuador',
            'El Salvador',
            'Fidschi',
            'Georgien',
            'Ghana',
            'Guatemala',
            'Guyana',
            'Honduras',
            'Hongkong',
            'Indien',
            'Indonesien',
            'Iran',
            'Irak',
            'Israel',
            'Jamaika',
            'Japan',
            'Jordanien',
            'Kambodscha',
            'Kasachstan',
            'Katar',
            'Kenia',
            'Kirgistan',
            'Kolumbien',
            'Kosovo',
            'Kuwait',
            'Lesotho',
            'Macau',
            'Malaysia',
            'Marokko',
            'Mazedonien',
            'Mexiko',
            'Moldawien',
            'Mongolei',
            'Montenegro',
            'Mosambik',
            'Myanmar',
            'Neuseeland',
            'Nicaragua',
            'Niger',
            'Nigeria',
            'Oman',
            'Pakistan',
            'Pal\u00E4stinensische Autonomiegebiete',
            'Panama',
            'Paraguay',
            'Peru',
            'Philippinen',
            'Puerto Rico',
            'Ruanda',
            'Russland',
            'Sambia',
            'Saudi Arabien',
            'Serbien',
            'Singapur',
            'Sri Lanka',
            'S\u00FCdafrika',
            'S\u00FCdkorea',
            'Surinam',
            'Tadschikistan',
            'Taiwan',
            'Tansania',
            'Thailand',
            'Trinidad und Tobago',
            'Tunesien',
            'Turks \u0026 Caicosinseln',
            'Ukraine',
            'Uruguay',
            'Verein. Arab. Emirate',
            'Wei\u00DFrussland'
        ];

        var countryListRow2 = [
            '\u00C4quatorialguinea',
            '\u00C4thiopien',
            'Belize',
            'Benin',
            'Bhutan',
            'Bolivien',
            'Botswana',
            'Burkina Faso',
            'Burundi',
            'Dschibuti',
            'Elfenbeink\u00FCste',
            'Franz\u00F6sisch-Polynesien',
            'Gabun',
            'Gambia',
            'Guam',
            'Guinea',
            'Jemen',
            'Kamerun',
            'Kongo (Republik)',
            'Laos',
            'Libanon',
            'Liberia',
            'Libyen',
            'Madagaskar',
            'Malawi',
            'Mali',
            'Mauretanien',
            'Mauritius',
            'Nepal',
            'Neukaledonien',
            'Niederl\u00E4ndische Antillen',
            'Norfolk Island',
            'Osttimor',
            'Papua-Neuguinea',
            'Salomon-Inseln',
            'Samoa',
            'Senegal',
            'Seychellen',
            'Simbabwe',
            'S\u00FCd-Sudan',
            'Swasiland',
            'Togo',
            'Tonga',
            'Tschad',
            'Turkmenistan',
            'Uganda',
            'Usbekistan',
            'Vanuatu',
        ];


        var countryListWorldExp = [
            'Angola',
            'Bahamas',
            'Gr\u00F6nland',
            'Kapverdische Inseln',
            'Kuba',
            'Malediven',
            'Maritime Services',
            'Namibia',
            'Roaming im Flugzeug',
            'Venezuela',
            'Vietnam'
        ];
    }

    var getCountryJSON = function() {
        // !IMPORTANT: check that you can cross domain origin
        var countryAPI = 'https://eweb3.vfd2-testnet.de/meinvodafone/app/master_roaming.json';

        return $.getJSON(countryAPI);
    };


    var getCountryRow = function(country) {
        if (-1 !== $.inArray(country, countryListEurope)) {
            return 'Europe';
        }

        if (-1 !== $.inArray(country, countryListTruschcan)) {
            return 'Truschcan';
        }

        if (-1 !== $.inArray(country, countryListRow)) {
            return 'RowPreferred';
        }

        if (-1 !== $.inArray(country, countryListRow2)) {
            return 'Row2';
        }

        if (-1 !== $.inArray(country, countryListWorldExp)) {
            return 'WeltExp';
        }
        return '';
    };

    // returns the searched options from the defined flow
    var getFlowOption = function(destination, country, tariff) {
        if ('prepaid' !== contractMode) {
            return '';
        } else {
            // TODO: maybe a bit ugly, pls refactore this funtion, when you see this
            if (undefined !== prepaid[destination]) {
                if (undefined !== prepaid[destination][country]) {
                    if (undefined !== prepaid[destination][country][tariff]) {
                        return prepaid[destination][country][tariff];
                    }
                }
            }
        }

        return 'notFound';
    };

    var createRoamingStepper = function($roamingStepper) {

        var $accordeon = $roamingStepper.find('.accordion .mod-toggle');

        var getDestination = function() {

            if ('' !== $accordeon.find('input[name=destination]:checked').val()) {
                return $accordeon.find('input[name=destination]:checked').val();
            }
            return '';
        };

        var getCountry = function() {

            if ('' !== $accordeon.find('input[name=country-destination]').val()) {
                return $accordeon.find('input[name=country-destination]').val();
            }
            return '';
        };

        var getTariff = function() {

            if ('' !== $accordeon.find('input[name=your-tariff]:checked').val()) {
                return $accordeon.find('input[name=your-tariff]:checked').val();
            }
            return '';
        };

        var getCurrentFlowOption = function() {
            var destination = getDestination();
            var country = getCountry();
            var tariff = getTariff();

            var option = getFlowOption(destination, country, tariff);
            if ('' !== option) {
                // first try was to find the specific country, when not found search the countryRow from the arrays
                if ('notFound' === option) {
                    option = getFlowOption(destination, getCountryRow(country), tariff);
                }

                // when nothing found, try at least the "other" option
                if ('notFound' === option) {
                    option = getFlowOption(destination, 'other', tariff);
                }
            }

            return option;
        };

        var disableInput = function($input) {
            $input.prop('disabled', 'disabled');
            $input.addClass('disabled');
        };

        var enableInput = function($input) {
            $input.prop('disabled', false);
            $input.removeClass('disabled');
        };

        var clearInput = function($input) {
            $input.val('');
            disableInput($input.parents('.tg-body').find('.next-step'));
        };

        var removeAccordeonChangeButton = function(step) {
            if ('all' === step) {
                $accordeon.find('.tg-item .tg-head .change-step').remove();
            } else {
                $($accordeon.find('.tg-item').get(step)).find('.tg-head .change-step').remove();
            }
        };

        var changeStepHeadline = function(step, additional) {
            var $tgHead = $($accordeon.find('.tg-item').get(step)).find('.tg-head');

            // remove just the old text, not the links and icons
            $tgHead.contents().filter(function() {
                return 3 === this.nodeType;
            }).remove();

            // remove old content if exists
            $tgHead.find('.item-headline').remove();
            // add custom headline
            $tgHead.append('<div class="item-headline"></div>');
            if ('' !== additional) {
                $tgHead.find('.item-headline').html('<strong>' + $tgHead.data('label') + ' </strong>' + additional);
            } else {
                $tgHead.find('.item-headline').html('<strong>' + $tgHead.data('label') + '</strong>');
            }
        };

        var resetStepHeadline = function(step) {
            var $tgHead = $($accordeon.find('.tg-item').get(step)).find('.tg-head');

            // remove just the old text, not the links and icons
            $tgHead.contents().filter(function() {
                return 3 === this.nodeType;
            }).remove();

            // remove old content if exists
            $tgHead.find('.item-headline').remove();
            // add custom headline
            $tgHead.append('<div class="item-headline"></div>');

            $tgHead.find('.item-headline').html($tgHead.data('label'));

        };

        // change logo to success, when all values are ok
        var changeLogoToSuccess = function(step) {
            var icon = '<svg class="i-xsml">' +
                '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/simplicity/svgdefs.svg#i-success-g-sml"></use>' +
                '</svg>';
            var $tgHead = $($accordeon.find('.tg-item').get(step)).find('.tg-head .tg-icon');
            $tgHead.find('div, svg').remove();
            $tgHead.append(icon);
        };

        var changeLogoToNumber = function(step) {
            var icon = '<div>' +
                '<span class="icon-wrap">' +
                '<i class="i-step-number-b-xsml">' +
                '<span class="step-number-digit">' + (step + 1) + '</span>' +
                '</i>' +
                '</span></div>';

            var $tgHead = $($accordeon.find('.tg-item').get(step)).find('.tg-head .tg-icon');
            $tgHead.find('div, svg').remove();
            $tgHead.append(icon);
        };

        //override is for cases, if you clearly want to set this state (expects true)
        var changeLogoToNumberDisabled = function(step, override) {

            var inputValue = '';
            var $accordeonBody = $($accordeon.find('.tg-item').get(step)).find('.tg-body .tg-board');

            if (0 < $accordeonBody.find('input[type="radio"]:checked').length) {
                inputValue = $accordeonBody.find('input[type="radio"]:checked').next('label').text();
            }

            if (0 < $accordeonBody.find('input[type="text"]').length) {
                inputValue = $accordeonBody.find('input[type="text"]').val();
            }
            // just if no content is selected
            if ('' === inputValue || true === override) {
                var icon = '<div class="disabled">' +
                    '<span class="icon-wrap">' +
                    '<i class="i-step-number-b-xsml">' +
                    '<span class="step-number-digit">' + (step + 1) + '</span>' +
                    '</i>' +
                    '</span></div>';

                var $tgHead = $($accordeon.find('.tg-item').get(step)).find('.tg-head .tg-icon');
                $($accordeon.find('.tg-item').get(step)).find('.tg-head').addClass('disabled');
                $tgHead.find('div, svg').remove();
                $tgHead.append(icon);
            }
        };

        var showFlowResult = function() {
            var destination = getDestination();
            var country = getCountry();
            var countryRow = getCountryRow(country);
            var tariff = getTariff();
            var $tgBody = $($accordeon.find('.tg-item').get(3)).find('.tg-body .tg-board .module');
            $tgBody.empty();
            var results = $roamingStepper.find('.offers .offer-item-wrapper[data-country-row="' + country + '"][data-tariff="' + tariff + '"][data-destination="' + destination + '"]');
            if (undefined === results || 0 === results.length) {
                results = $roamingStepper.find('.offers .offer-item-wrapper[data-country-row="' + countryRow + '"][data-tariff="' + tariff + '"][data-destination="' + destination + '"]');
            }
            if (undefined === results || 0 === results.length) {
                results = $roamingStepper.find('.offers .offer-item-wrapper[data-country-row="other"][data-tariff="' + tariff + '"][data-destination="' + destination + '"]');
            }

            if (undefined === results || 0 === results.length) {
                results = '';
            }
            if ('' !== results) {
                $.each(results, function(index, result) {
                    $tgBody.append($(result).html());
                });
            } else {
                $tgBody.append('<div>Leider wurde kein Angebot für Sie gefunden, bitte überprüfen Sie Ihre Eingaben. Oder kontakieren Sie uns telefonisch.</div>');
            }

        };

        var showFlowResultPrepaid = function() {
            var results = getCurrentFlowOption();
            var $tgBody = $($accordeon.find('.tg-item').get(3)).find('.tg-body .tg-board .module');
            $tgBody.empty();
            if ('notFound' !== results) {
                if ('inForeignCountries' === getDestination()) {
                    if (false === isNotFreeEU(results)) {
                        /*var imageTextHeadline = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-imageTextHeadline"]').data('value');
                        var imgSrc = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-imgSrc"]').data('value');
                        var imgSrcMobile = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-imgSrcMobile"]').data('value');
                        var textOnlyHeadline = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-textOnlyHeadline"]').data('value');
                        var textOnlyText = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-textOnlyText"]').data('value');
                        var textOnlyH5 = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-textOnlyH5"]').data('value');
                        var textOnlyH5Text = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-textOnlyH5Text"]').data('value');
                        var textOnlyH4b = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-textOnlyH4b"]').data('value');
                        var textOnlyH4bText = $roamingStepper.find('.stepper-prepaid-value[data-key="free-eu-textOnlyH4bText"]').data('value');*/
                        //$tgBody.append(getImageText(imageTextHeadline, EURoamingText, '', imgSrc, imgSrcMobile));
                        //$tgBody.append(getTextOnlyFreeEUHTML(textOnlyHeadline, textOnlyText, textOnlyH5, textOnlyH5Text, textOnlyH4b, textOnlyH4bText, results.Link));
                        $tgBody.append(getTextOnlyHTML('', 'Diese Preise gelten im EU-Ausland für Gespräche von CallYa ins Festnetz und in alle Mobilfunknetze. Es gilt eine Taktung von 30/1, wenn nichts anderes in der Tabelle angegeben ist.'));
                        $tgBody.append(getTableHTML(
                            {
                                tableHeadline: results.TableHeadline,
                                outgoingMobile: results.OutgoingCallMobile,
                                outgoingHome: results.OutgoingCallHome,
                                incomingMobile: results.IncomingCallMobile,
                                incomingHome: results.IncomingCallHome,
                                sms: results.SMS,
                                mms: results.MMS,
                                data: results.Data
                            })
                        );
                        vf['responsive-table'].init();
                        $tgBody.append(getTextOnlyHTML('', '<b>' + results.EURoamingText + '</b>'));
                        $tgBody.append(getButtonHTML(results.ButtonText, results.ButtonColor, results.Link));
                    } else {
                        /*var topHeadline = $roamingStepper.find('.stepper-prepaid-value[data-key="not-free-eu-topHeadline"]').data('value');
                        var topText = $roamingStepper.find('.stepper-prepaid-value[data-key="not-free-eu-topText"]').data('value');
                        var bottomHeadline = $roamingStepper.find('.stepper-prepaid-value[data-key="not-free-eu-bottomHeadline"]').data('value');

                        var footnote = results.footnote !== undefined ? results.footnote : topText;
                        var UpsellTariff = results.UpsellTariff !== undefined ? results.UpsellTariff : '';*/
                        //$tgBody.append(getTextOnlyHTML(topHeadline, footnote, ''));
                        $tgBody.append(getTextOnlyHTML('', 'Diese Preise gelten im EU-Ausland für Gespräche von CallYa ins Festnetz und in alle Mobilfunknetze. Es gilt eine Taktung von 30/1, wenn nichts anderes in der Tabelle angegeben ist.'));
                        $tgBody.append(getTableHTML(
                            {
                                tableHeadline: results.TableHeadline,
                                outgoingMobile: results.OutgoingCallMobile,
                                outgoingHome: results.OutgoingCallHome,
                                incomingMobile: results.IncomingCallMobile,
                                incomingHome: results.IncomingCallHome,
                                sms: results.SMS,
                                mms: results.MMS,
                                data: results.Data
                            })
                        );
                        vf['responsive-table'].init();
                        $tgBody.append(getTextOnlyHTML('', '<b>' + results.EURoamingText + '</b>'));
                        $tgBody.append(getButtonHTML(results.ButtonText, results.ButtonColor, results.Link));
                    }
                } else if ('toForeignCountries' === getDestination()) {
                    $tgBody.append(getTableHTML(
                        {
                            tableHeadline: results.TableHeadline,
                            outgoingMobile: results.OutgoingCallMobile,
                            outgoingHome: results.OutgoingCallHome,
                            incomingMobile: results.IncomingCallMobile,
                            incomingHome: results.IncomingCallHome,
                            sms: results.SMS,
                            mms: results.MMS,
                            data: results.Data
                        })
                    );
                    vf['responsive-table'].init();
                    $tgBody.append(getFootnoteHTML(results.Footnote));
                    vf.footnotes.init('');
                    $tgBody.find('.accordion .mod-toggle').unbind('click');
                    $tgBody.find('.accordion .mod-toggle a').click(false);
                }
            } else {
                $tgBody.append('<div>Leider wurde kein Angebot für Sie gefunden, bitte überprüfen Sie Ihre Eingaben. Oder kontakieren Sie uns telefonisch.</div>');
            }
        };

        var setCountryInputState = function($input) {
            if ('prepaid' !== contractMode) {
                var countryRow = getCountryRow($input.val());
            } else {
                var countryRow = getRawCountryList()[$input.val()];
            }
            if ('' !== countryRow && undefined !== countryRow) {
                enableInput($input.parents('.tg-body').find('.next-step'));
            } else {
                disableInput($input.parents('.tg-body').find('.next-step'));
                changeLogoToNumberDisabled(2, true);
            }
        };

        var enableAutoComplete = function($input, json) {
            var options = {
                data: json,
                getValue: 'name',
                list: {
                    match: {
                        enabled: true,
                        method: function(element, phrase) {
                            if (0 === element.search(phrase)) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                    },
                    maxNumberOfElements: 8,
                    onSelectItemEvent: function() {
                        setCountryInputState($input);
                    }
                },
                template: {
                    type: 'custom',
                    method: function(value, item) {
                        var result = '';
                        var additionalClass = 'no-flag';
                        if ('' !== item.code) {
                            result = '<img src="http://media.vodafone.de/www/images/app/' + (item.code).toLowerCase() + '_flag.png" width="19px" class="flag">';
                            additionalClass = '';
                        }
                        return result + '<span class="' + additionalClass + '">' + value + '</span>';
                    }
                },

                theme: 'square'
            };

            $input.easyAutocomplete(options);
        };

        var normalizeCountries = function(countries) {
            var normalized = countries;

            $.each(normalized, function(index, value) {
                normalized[index].name = value.name.replace('&Auml;', '\u00C4');
                normalized[index].name = value.name.replace('&auml;', '\u00E4');
                normalized[index].name = value.name.replace('&Ouml;', '\u00D6');
                normalized[index].name = value.name.replace('&ouml;', '\u00F6');
                normalized[index].name = value.name.replace('&Uuml;', '\u00DC');
                normalized[index].name = value.name.replace('&uuml;', '\u00FC');
                normalized[index].name = value.name.replace('&amp;', '\u0026');
                normalized[index].name = value.name.replace('&szlig;', '\u00DF');
            });

            normalized.push({
                alternateCode: '',
                code: '',
                name: 'Maritime Services'
            });

            normalized.push({
                alternateCode: '',
                code: '',
                name: 'Roaming im Flugzeug'
            });

            return normalized;
        };

        var jsonCountryProxy = function() {

            var $input = $roamingStepper.find('.roaming-stepper-item input[name=country-destination]');
            if ('prepaid' !== contractMode) {
                var jsonRequest = getCountryJSON();
                jsonRequest.done(function(items) {
                    var countries = normalizeCountries(items.countries);
                    enableInput($input);
                    enableAutoComplete($input, countries);
                });
            } else {
                var countries = getCountryList(getDestination());
                enableInput($input);
                enableAutoComplete($input, countries);
            }
        };

        var sendingTracking = function(step) {

            //var $tgHead = $($accordeon.find('.tg-item').get(step)).find('.tg-head');
            //var stepName = $tgHead.data('label');

            var stepStatus = '';

            if (0 === step) {
                stepStatus = ['start'];
            }
            if (3 === step) {
                stepStatus = ['end', 'success'];
            }
            // the stepname provided by adversitement
            var providedStepName = '';
            if (0 === step) {
                providedStepName = 'dein ziel';
            } else if (1 === step) {
                providedStepName = 'dein land';
            } else if (2 === step) {
                providedStepName = 'deinen aktuellen tarif';
            } else if (3 === step) {
                providedStepName = 'deinen optionen';
            }

            // step + 1, because in tracking the first number is 1 instead of 0
            var trackingValues = [
                'pageview', {
                    'siteArea': 'mobility',
                    'platformType': 'responsive',
                    'loginStatus': 'not logged in',
                    'siteStructure': [
                        'privatkunden',
                        'roamingberater',
                        providedStepName
                    ],
                    'pageType': 'roamingberater',
                    'domain': 'eweb3.vfd2-testnet.de',
                    'oForm': {
                        'name': 'roamingberater',
                        'formType': 'roamingberater',
                        'formStep': step + 1,
                        'formStepName': providedStepName,
                        'formStatus': stepStatus
                    }
                }
            ];

            if (0 < step) {
                var $accordeonBody = $($accordeon.find('.tg-item').get(0)).find('.tg-body .tg-board');
                trackingValues[1].roamingGoal = $accordeonBody.find('input[type="radio"]:checked').next('label').text();
            }
            if (1 < step) {
                var $accordeonBody = $($accordeon.find('.tg-item').get(1)).find('.tg-body .tg-board');
                trackingValues[1].roamingCountry = $accordeonBody.find('input[type="text"]').val();
            }
            if (2 < step) {
                var $accordeonBody = $($accordeon.find('.tg-item').get(2)).find('.tg-body .tg-board');
                trackingValues[1].roamingTariff = $accordeonBody.find('input[type="radio"]:checked').next('label').text();
            }
            window._ddq = window._ddq || [];
            _ddq.push(trackingValues);

        };

        var closeAllSteps = function() {
            $accordeon.find('.tg-item .tg-body').hide();
        };

        var setPrepaidTariffOptions = function(){
            var options = $accordeon.find('input[name="your-tariff"]');
            if (undefined !== prepaid[getDestination()]) {
                if (undefined !== prepaid[getDestination()][getCountry()]) {
                    options.each(function(key, input){
                        var $input = $(input);
                        if (undefined !== prepaid[getDestination()][getCountry()][$input.val()]) {
                            $input.parent().show();
                        } else {
                            $input.parent().hide();
                        }
                    });
                }
            }
        };

        var goToStep = function(step) {
            if ('prepaid' === contractMode) {
                jsonCountryProxy();
                setPrepaidTariffOptions();
            }

            $accordeon.find('.tg-head').removeClass('disabled');
            $accordeon.find('.tg-head').removeClass('tg-active');
            $($accordeon.find('.tg-item').get(step)).find('.tg-head').addClass('tg-active');
            $accordeon.find('.tg-item').each(function(index) {
                // previous steps and the last one
                if (index < step || 3 === step) {
                    changeLogoToSuccess(index);
                } else if (index === step) {
                    changeLogoToNumber(index);
                } else {
                    changeLogoToNumberDisabled(index);
                }
            });
            closeAllSteps();
            $($accordeon.find('.tg-item').get(step)).find('.tg-body').show();
            // last step, show the results
            if (3 === step) {
                if ('prepaid' !== contractMode) {
                    showFlowResult();
                } else {
                    showFlowResultPrepaid();
                }
            }
        };

        var changeStep = function() {
            // go to step of the clicked item
            if (false === $(this).parents('.tg-head').hasClass('disabled')) {
                goToStep($(this).parents('.tg-item').index());
            }
        };

        var addAccordeonChangeButton = function(step) {
            var button = '<div class="change-step">\u00E4ndern</div>';
            var $tgHead = $($accordeon.find('.tg-item').get(step)).find('.tg-head');
            // in case some change button is already setted
            $tgHead.find('.change-step').remove();
            $tgHead.append(button);
            $tgHead.find('.change-step').click(changeStep);
        };

        var nextStep = function(step) {
            var additionalText = '';
            var $accordeonBody = $(this).parents('.tg-item').find('.tg-body .tg-board');
            if (0 < $accordeonBody.find('input[type="radio"]:checked').length) {
                additionalText = $accordeonBody.find('input[type="radio"]:checked').next('label').html();
            }

            if (0 < $accordeonBody.find('input[type="text"]').length) {
                additionalText = $accordeonBody.find('input[type="text"]').val();
            }

            if (0 <= step) {
                goToStep(step + 1);
                addAccordeonChangeButton(step);
                changeStepHeadline(step, additionalText);
                sendingTracking(step + 1);
                // welcome to the last step, set this as done
                if (3 === (step + 1)) {
                    changeStepHeadline(step + 1, '');
                }
            } else {
                goToStep($(this).parents('.tg-item').index() + 1);
                addAccordeonChangeButton($(this).parents('.tg-item').index());
                changeStepHeadline($(this).parents('.tg-item').index(), additionalText);
                sendingTracking($(this).parents('.tg-item').index() + 1);
                // welcome to the last step, set this as done
                if (3 === ($(this).parents('.tg-item').index() + 1)) {
                    changeStepHeadline($(this).parents('.tg-item').index() + 1, '');
                }
            }
        };

        var clearAllSteps = function() {
            removeAccordeonChangeButton('all');
            // clear all inputs
            $roamingStepper.find('input,label').removeClass('checked');
            $roamingStepper.find('input:not([type="radio"])').val('');
            $roamingStepper.find('input[type="radio"]').attr('checked', false);

            $roamingStepper.find('.next-step').each(function() {
                disableInput($(this));
            });
            $roamingStepper.find('.tg-item').each(function(index) {
                resetStepHeadline(index);
            });
            // go to first step
            goToStep(0);

            var $scrollToElement = $($accordeon.find('.tg-item').get(0)).find('.tg-head');
            $('html, body').animate({
                scrollTop: $scrollToElement.offset().top
            }, 200);
        };

        var disableAccordeonOnClickOpen = function() {
            $accordeon.unbind('click');
            $accordeon.find('a').click(false);
        };

        var addNextButton = function(step) {
            var button = '<button class="next-step disabled btn btn-sml" disabled="disabled">Zum n\u00E4chsten Schritt</button>';
            var $tgBody = $($accordeon.find('.tg-item').get(step)).find('.tg-body');
            $tgBody.append(button);
            $tgBody.find('.next-step').click(nextStep);
        };

        var addResetButton = function() {
            var button = '<button class="reset-steps btn btn-sml btn-alt">Zur\u00FCcksetzen</button>';
            var $tgBody = $($accordeon.find('.tg-item').get(3)).find('.tg-body');
            $tgBody.append(button);
            $tgBody.find('.reset-steps').click(clearAllSteps);
        };

        $roamingStepper.find('.delete-content').click(function() {
            clearInput($(this).parent().find('input'));
        });

        $roamingStepper.find('.change-step').click(changeStep);

        $roamingStepper.find('input[type="radio"]').click(function() {
            enableInput($(this).parents('.tg-body').find('.next-step'));
        });

        $roamingStepper.find('input[type="text"]').change(function() {
            setCountryInputState($(this));
        });

        var init = function() {
            disableAccordeonOnClickOpen();

            goToStep(0);
            jsonCountryProxy();
            addNextButton(0);
            addNextButton(1);
            addNextButton(2);
            addResetButton();
            //initial Tracking
            sendingTracking(0);
        };
        init();
    };

    var init = function() {
        $(settings.target).each(function() {
            createRoamingStepper($(this));
        });
    };


    vf['roaming-stepper'] = {
        settings: settings,
        init: init
    };
}(vf));

(function () {//eslint-disable-line strict

    vf['multi-select'] = {
        settings: {
            target: '.mod-multi-select',
            imagesClass: 'ms-images',
            iconActive: 'i-step-number-alt',
            iconSelected: 'i-tick-green-sml',
            iconDisabled: 'i-step-number',
            separator: ' — ',
            select: '<div class="fm-data">' +
            '<label for="{id}"><span class="icon-wrap"><i class="">{num}</i></span>{label}</label>' +
            '<div class="fm-select">' +
            '<select id="{id}"></select>' +
            '<span><i class="i-arrow-down-b-xsml"></i></span>' +
            '</div>' +
            '</div>',
            hiddenInput: '<input type="hidden" name="{name}" class="original-select" value="">',
            option: '<option value="{val}">{name}</option>',
            li: '<li class="{show}"><div><img src="{src}"><span>{name}</span></div></li>',
            imageList: '<div class="ms-images cf only-{size}" data-id="{id}">' +
            '<ul class="cta-topic">{li}</ul>{more}' +
            '</div>'
        },

        init: function (context) {
            var s = this.settings,
                targets = $(s.target, context);

            targets.each(function () {
                var target = $(this),
                    id = target.attr('id'),
                    labels = target.data('labels').split(';'),
                    tree,
                    html = '',
                    required;

                tree = vf['multi-select'].generateTree(target);

                if (target.hasClass('ajax')) {
                    target.on('submit', $.proxy(vf['multi-select'].ajaxLoad, target));
                }

                // Replace original html with new select boxes
                for (var i = 1; i <= labels.length; i++) {

                    html += s.select
                        .replace(/\{id\}/g, id + i)
                        .replace(/\{label\}/g, labels[i - 1])
                        .replace(/\{num\}/g, i);

                    if (4 === i) { html = html.replace(/fm\-data/g, 'fm-data ms-quart'); }
                }
                // Save original select boxes as hidden inputs
                $('select', target).each(function () {
                    if (!required && $(this).hasClass('fm-required')) { required = true; }
                    html += s.hiddenInput.replace(/\{name\}/g, $(this).attr('name'));
                });
                target.html(html);

                // Sets the first field as required
                if (required) { target.find('select').eq(0).addClass('fm-required'); }

                // Populate dropdown
                vf['multi-select'].updateSelect(tree, target);

                target.on('change', 'select', function (e) {
                    var position = parseInt($(e.target).attr('id').replace(id, ''));

                    vf['multi-select'].updateSelect(tree, target, position);
                });

                // Pre-populate selects with values coming from url
                vf['multi-select'].prePopulate(tree, target);

                // Trigger select change on image click
                target.on('click', '.' + s.imagesClass + ' li div', function (e) {
                    var etarget = $(e.target).closest('div'),
                        select = target.find('#' + etarget.closest('.' + s.imagesClass, target).data('id'));

                    select.val($('span', etarget).text()).change();
                });

                // View more button
                target.on('click', '.ms-view-more .btn', vf['multi-select'].viewMore);

            });
        },

        // View more button functionality, reveal hidden image list items
        viewMore: function (e) {

            e.preventDefault();
            $(e.target).parent('.ms-view-more').prev().find('li').addClass('show').end().end().remove();
        },

        // Object creation based on the no-JS select/optgroup/option structure
        generateTree: function (target) {
            var s = this.settings,
                tree = {},
                levelOne, levelTwo, selectName,
                countSelects = 0;

            // Extends tree object with element values
            function addChild(elem) {
                var obj = {},
                    split;

                obj.img = elem.data('img');
                if ('OPTION' !== elem[0].tagName) { obj.children = {}; }

                switch (elem[0].tagName) {
                // Level 1
                case 'SELECT':
                    tree[levelOne] = obj;
                    break;
                // Level 2
                case 'OPTGROUP':
                    tree[levelOne].children[levelTwo] = obj;
                    break;
                // Level 3
                case 'OPTION':
                    // 4th level navigation
                    split = elem.text().split(s.separator);
                    if (split[1]) {

                        elem.siblings().addBack().each(function (i, option) {
                            var option = $(option),
                                splitOption = option.text().split(s.separator);

                            if (split[0] === splitOption[0]) {
                                if (option.data('img-main')) { obj.img = option.data('img-main'); }
                                if (!obj.children) { obj.children = {}; }
                                obj.children[splitOption[1]] = {};
                                obj.children[splitOption[1]].img = option.data('img');
                                obj.children[splitOption[1]].val = selectName + '=' + option.attr('value');
                                obj.children[splitOption[1]].os = 'true'; // Adds extra os name/value for use later
                                option.data('skip', true);
                            }
                        });
                        tree[levelOne].children[levelTwo].children[split[0]] = obj;

                        // Without 4th level
                    } else {
                        obj.val = selectName + '=' + elem.attr('value');
                        tree[levelOne].children[levelTwo].children[elem.text()] = obj;
                    }
                    break;
                }
            }

            // Generate tree object using data from original select boxes
            $('select', target).each(function () {
                var select = $(this);

                levelOne = $('label[for=' + select.attr('id') + ']', target).text();
                selectName = select.attr('name');

                addChild(select);
                countSelects++;

                $('optgroup', select).each(function () {
                    var optgroup = $(this);

                    levelTwo = optgroup.attr('label');
                    addChild(optgroup);

                    $('option', optgroup).each(function () {
                        var option = $(this);

                        if (!option.data('skip')) { addChild(option); }
                    });
                });
            });

            // For 2 level multi-select we don't need select values, jump to next level in tree
            if (1 === countSelects) { tree = tree[levelOne].children; }

            return tree;
        },

        // Pre-populate selects with values coming from url
        prePopulate: function (tree, target, msId) {

            $('select', target).each(function () {
                var select = $(this),
                    id = select.attr('id'),
                    position = parseInt(id.replace(msId, '')),
                    value = decodeURI((RegExp('[?|&]' + id + '=' + '(.+?)(&|$)').exec(location.search) || [])[1]);

                if (value) {
                    select.val(value);
                    vf['multi-select'].updateSelect(tree, target, position);
                }
            });
        },

        // Populates dropdowns and image list
        updateSelect: function (tree, target, position) {
            var s = this.settings,
                viewMore = '<div class=" ms-view-more"><a href="#" class="btn btn-sml btn-alt">' + vf.config.string['view-more'] + '</a></div>',
                id = target.attr('id'),
                branch = tree,
                form = $('.fm-data', target).parents('form'), //eslint-disable-line no-unused-vars
                selectsVal = [],
                html = '',
                lis = '',
                next = 1,
                count = 1;

            // Get select values and find the tree branch to update
            $('select', target).each(function (i) {

                if (!this.value || i >= position) { return false; }

                selectsVal.push(this.value);
                branch = (branch[this.value].val) ? branch[this.value] : branch[this.value].children;
                next = i + 2;

                return true;
            });

            $('select', target).each(function (i) {
                var select = $(this),
                    fmData = select.closest('.fm-data', target),
                    icon = select.closest('.fm-data', target).find('label i'),
                    instructions = select.parents('.find-device').next('.ms-instructions'),
                    key;

                // Update icons
                if (next - 1 === i) {
                    fmData.addClass('ms-active').css('opacity', '1');
                    icon.attr('class', s.iconActive);
                } else if (next - 1 > i) {
                    fmData.removeClass('ms-active');
                    icon.attr('class', s.iconSelected).empty();
                } else {
                    fmData.removeClass('ms-active').css('opacity', '.6');
                    icon.attr('class', s.iconDisabled);
                }

                // Disable inactive dropdowns
                if (next - 1 < i) { select.prop('disabled', 'disabled').html(''); }

                // Instructions shown only on 4th level
                instructions.hide();

                // Hide/show 4th dropdown, instructions
                if (3 === i) {
                    select.closest('.fm-data').hide();
                    if (4 <= next) {
                        for (key in branch) {
                            if (branch[key].val || 4 < next) {
                                select.closest('.fm-data').show();
                                break;
                            }
                        }
                    }
                }
                // Check if os name/value exists to show instruction panel
                $.each(branch, function (name, attr) {
                    if (attr) {
                        if (attr.os) {
                            instructions.show();
                            return;
                        }
                    }
                });
            });

            // Update hidden inputs and submit form
            vf['multi-select'].updateOriginal(branch.val, target);

            if (branch.val) { return; }

            // GENERATE HTML
            // First option value is empty, name repeats label, eg.: Select Your device type

            selectLabel = vf.config.string['multi-select-first']
                .replace(/\{label\}/, target.data('labels').split(';')[next - 1]);
            html += s.option
                .replace(/\{name\}/g, selectLabel)
                .replace(/\{val\}/g, '');

            $.each(branch, function (name, attr) {
                // Options
                html += s.option
                    .replace(/\{name\}/g, name)
                    .replace(/\{val\}/g, name);
                // Image li
                lis += s.li
                    .replace(/\{src\}/g, attr.img)
                    .replace(/\{name\}/g, name)
                    .replace(/\{show\}/g, 12 >= count ? 'show' : '');
                count++;
            });
            $('#' + id + next, target).html(html).prop('disabled', false);

            // Generate image list
            if (target.data('images')) {

                $('.' + s.imagesClass, target).remove();

                html = s.imageList
                    .replace(/\{li\}/g, lis)
                    .replace(/\{id\}/g, id + next)
                    .replace(/\{more\}/g, 12 < count ? viewMore : '');

                // Insert image html for large
                target.append(html.replace(/\{size\}/g, 'lrg'));

                // Insert image html for small
                $('#' + id + next, target).closest('.fm-data', target).after(html.replace(/\{size\}/g, 'sml'));
            }
        },

        // Update original hidden input fields and submit form when value is selected
        updateOriginal: function (value, target) {
            value = (value) ? value.split('=') : [value];

            $('.original-select', target).each(function () {
                var name = $(this).attr('name');

                $('input[name=' + name + ']', target).attr('value', name === value[0] ? value[1] : '');
            });

            // Auto submit form when value is selected and module is the whole form
            if (value[1] && target.is('form')) { target.submit(); }
        },
        ajaxLoad: function (e) {

            e.preventDefault();

            var form = this,
                url = form.attr('action').split('#'),
                href = url[0],
                id = '#' + url[1],
                drop = form.next('.load-space');

            $.ajax({
                type: 'POST',
                url: href,
                data: form.serialize(),
                success: function (data) {
                    drop.html($(data).find(id));
                    vf.util.initModules(drop);
                    if (form.data('images')) {
                        // Hides images once ajax content is loaded
                        // May need to be rethougt images need to remain.
                        form.find('.ms-images').hide();
                        form.find('.fm-data').css('border-bottom', '1px solid #e5e5e5');
                    }
                }
            });
        }
    };

}(vf));

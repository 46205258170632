(function () {//eslint-disable-line strict

    vf['carousel-module'] = {
        settings: {
            target: '.carousel-module'
        },
        /**
        * all the carousel relevat settings are stored in the model (size, version )
        */
        aSliderModels: null,

        /**
        * initializes oModel and bx component
        * adds reseize event hanbler
        */
        init: function () {
            var that = this;
            // create a new slider model array
            aSliderModels = [];

            // find all bx holders
            var aBxHolderDivs = $('.mod-carousel-module');

            for (var i = 0; i < aBxHolderDivs.length; i++) {
                var oBxHolderDiv = aBxHolderDivs[i];
                oBxHolderDiv.classList.add('bx-holder_' + i);
                var sClassName = '.bx-holder_' + i;
                var oWrapper = $('body').find('.wrapper');
                if ($(sClassName).length) {
                    if (oBxHolderDiv.classList.contains('small-tiles')) {
                        if (oWrapper.hasClass('enterprise')) {
                            aSliderModels.push(that.createSliderModel($(sClassName), 'SMALL', 'ENTERPRISE'));
                        }
                        else {
                            aSliderModels.push(that.createSliderModel($(sClassName), 'SMALL', 'CONSUMER'));
                        }
                    }
                    else {
                        // look for the the wrapper class

                        if (oWrapper.hasClass('enterprise')) {
                            aSliderModels.push(that.createSliderModel($(sClassName), 'LARGE', 'ENTERPRISE'));
                        }
                        else {
                            aSliderModels.push(that.createSliderModel($(sClassName), 'LARGE', 'CONSUMER'));
                        }
                    }
                }
            }

            $(window).resize(function () {

                that.loopApplySettings(false);
            });


            $(window).scroll(function () {

                that.loopApplyScroll();
            });


            $(window).load(function () {
                that.loopApplySettings(true);
                if ('mobile' !== vf.util.layout(true)) {
                    that.loopHideSlides();
                }

            });
            that.loopApplySettings(true);

        },
        /**
        * helper method for above
        */
        loopApplySettings: function (bLoadFlag) {


            for (var i = 0; i < aSliderModels.length; i++) {
                if (bLoadFlag) {
                    aSliderModels[i].bImagesLoaded = true;
                }
                this.applySettings(aSliderModels[i]);
            }
        },
        /**
        * helper method for above
        */
        loopHideSlides: function () {
            for (var i = 0; i < aSliderModels.length; i++) {
                this.hideSlidesToTheRight(aSliderModels[i]);
                this.scrollHandler(aSliderModels[i]);
            }
        },
        loopApplyScroll: function () {
            for (var i = 0; i < aSliderModels.length; i++) {
                this.scrollHandler(aSliderModels[i]);
            }
        },
        /**
        * creates model object for carousel instnces
        * @return {object} - the model
        */
        createSliderModel: function (oHolderDiv, sTileSize, sVersion) {
            var oSliderModel = {};
            oSliderModel.bImagesLoaded = false;
            oSliderModel.oHolderDiv = oHolderDiv;
            oSliderModel.sTileSize = sTileSize;
            oSliderModel.sVersion = sVersion;
            oSliderModel.oBxSliderInstance = null;
            oSliderModel.rightButtonPos = null;
            oSliderModel.leftButtonPos = null;
            oSliderModel.iNumberOfSlides = 0;
            oSliderModel.iDummySlidesAdded = 0;
            oSliderModel.oSettings = null;


            return oSliderModel;
        },
        /**
        * apply settings clalled at init and stage resize
        * @param {object} oSliderModel - the model for the carousel instance
        */
        applySettings: function (oSliderModel) {
            //console.log("bImagesLoaded " + oSliderModel.bImagesLoaded    );
            if (!oSliderModel.bImagesLoaded) {
                return;
            }
            var oSliderRef = oSliderModel.oHolderDiv.find('.bxslider');
            // get the settings
            var oSettings;
            if ('CONSUMER' === oSliderModel.sVersion) {
                oSettings = this.getConsumerSettings(oSliderModel.sTileSize, oSliderModel);
            }
            else {
                oSettings = this.getEnterpriseSettings(oSliderModel.sTileSize, oSliderModel);
            }
            oSliderModel.oSettings = oSettings;

            // create one if necessary
            if (null === oSliderModel.oBxSliderInstance) {
                oSliderModel.oBxSliderInstance = oSliderRef.bxSlider(oSettings);
            }

            // set the initial number of slides value
            if (0 === oSliderModel.iNumberOfSlides) {
                oSliderModel.iNumberOfSlides = oSliderModel.oBxSliderInstance.getSlideCount();
            }
            // set the width of the holder
            oSliderModel.oHolderDiv.css('width', oSettings.iHolderWidth);

            // add / remove slides if needed
            this.addOrRemoveDummySlides(oSliderModel, oSettings);

            // apply the settings
            oSliderModel.oBxSliderInstance.reloadSlider(oSettings);

            // make sure that the slider is visible
            oSliderRef.css('opacity', 1);

            // set the overfow to visible if it is not in dektop mode
            if ('desktop' === vf.util.layout(true)) {
                oSliderModel.oHolderDiv.find('.bx-viewport').css('overflow', 'hidden');
            }
            else {
                oSliderModel.oHolderDiv.find('.bx-viewport').css('overflow', 'visible');
                var iPagerPos = $(window).width() / 2 - oSettings.iHolderWidth / 2;

                if ('SMALL' === oSliderModel.sTileSize) {

                    var iTileHeight = oSliderModel.oHolderDiv.find('li').css('height');
                    oSliderModel.oHolderDiv.find('.bx-viewport').css('height', iTileHeight);
                }


            }

            //    adjust next and prev buttons
            oSliderModel.oHolderDiv.find('.bx-next').css('left', oSettings.rightButtonPos);
            oSliderModel.oHolderDiv.find('.bx-prev').css('left', oSettings.leftButtonPos);

            // hide/view navigation elements and indicators
            if (true === vf.util.is_touch_device && 'desktop' !== vf.util.layout(true)) {
                oSliderModel.oHolderDiv.find('.bx-next').css('display', 'none');
                oSliderModel.oHolderDiv.find('.bx-prev').css('display', 'none');
            }

            // adjust the positon of the background
            if ('desktop' !== vf.util.layout(true)) {
                // adjust the position of the indicator
                if ('tablet' === vf.util.layout(true)) {
                    var iPagerPos = ($(window).width() - 16) / 2 - oSettings.iHolderWidth / 2;
                }
                else {
                    var iPagerPos = ($(window).width() - 20) / 2 - oSettings.iHolderWidth / 2;
                }

                oSliderModel.oHolderDiv.find('.bx-pager').css('left', iPagerPos);
            }

            // make all slides the same height

            this.makeEqualHeight(oSliderModel);





        },

        makeEqualHeight: function (oSliderModel) {

            var iBtnHeigth = 0;
            // get the botton height
            oSliderModel.oHolderDiv.find('li').each(function () {
                var h = $(this).find('.btn-wrap').height();
                if (iBtnHeigth < h) {
                    iBtnHeigth = h;
                }
            });

            // get the iMax height
            var iMaxHeight = 0;
            oSliderModel.oHolderDiv.find('li').each(function () {
                if (false === $(this).hasClass('empty-slide')) {
                    var myHeight = $(this).outerHeight();

                    if (myHeight > iMaxHeight) {
                        iMaxHeight = myHeight;
                    }
                }

            });

            iMaxHeight += iBtnHeigth;

            if ('SMALL' === oSliderModel.sTileSize) {
                iMaxHeight += 10;
            }
            else {
                iMaxHeight += 20;
            }


            // adjust the hight of all list elements
            oSliderModel.oHolderDiv.find('li').each(function () {
                $(this).css('height', iMaxHeight);

            });

            // adjust the hight of the vieport if necessary
            if (oSliderModel.oHolderDiv.find('.bx-viewport').height() !== iMaxHeight) {
                oSliderModel.oHolderDiv.find('.bx-viewport').css('height', iMaxHeight + 'px');
            }

        },

        addOrRemoveDummySlides: function (oSliderModel, oSettings) {
            var iDesktopSlidesMax;
            if ('CONSUMER' === oSliderModel.sVersion) {
                if ('LARGE' === oSliderModel.sTileSize) {
                    iDesktopSlidesMax = 4;
                }
                else {
                    iDesktopSlidesMax = 6;
                }
            }
            else {
                if ('LARGE' === oSliderModel.sTileSize) {
                    iDesktopSlidesMax = 3;
                }
                else {
                    iDesktopSlidesMax = 6;
                }
            }
            // enterprise

            // if it used on desktop
            if ('desktop' !== vf.util.layout(true)) {
                // remove all additional slides
                for (var i = 0; i < oSliderModel.iDummySlidesAdded; i++) {
                    oSliderModel.oHolderDiv.find('.bxslider li:last-child').remove();
                }
                oSliderModel.iDummySlidesAdded = 0;
                oSettings.moveSlides = 1;

            }
            // it's a desktop
            else {
                if (0 !== oSliderModel.iDummySlidesAdded) {
                    return;
                }
                var remainder = (oSliderModel.iNumberOfSlides - oSliderModel.iDummySlidesAdded) % iDesktopSlidesMax;
                if (0 !== remainder) {
                    remainder = iDesktopSlidesMax - remainder;
                }
                oSliderModel.iDummySlidesAdded = remainder;
                for (var i = 0; i < remainder; i++) {
                    oSliderModel.oHolderDiv.find('.bxslider').append('<li class="empty-slide"></li>');
                }

                oSettings.moveSlides = iDesktopSlidesMax;
            }
        },
        /**
        * Get the configuration object vor the consumer version
        * @param {string} sTileSize - the size either SMALL or LARGE
        * @return {object} - the settings object
        */
        getConsumerSettings: function (sTileSize) {
            var iWidowWidth = $(window).width();
            var oSettings = {};
            // defines the witdth of the next/prev buttons
            var iButtonWidth = 35;
            oSettings.easing = 'ease';
            oSettings.infiniteLoop = false;
            oSettings.speed = 400;
            oSettings.hideControlOnEnd = true;

            switch (vf.util.layout(true)) {
            case 'mobile':
                if ('LARGE' === sTileSize) {
                    oSettings.minSlides = oSettings.maxSlides = 1;
                    oSettings.iHolderWidth = iWidowWidth / 1.666666666666;
                    oSettings.slideWidth = oSettings.iHolderWidth;
                    oSettings.slideMargin = 8;
                }
                else {
                    oSettings.minSlides = 1;
                    oSettings.maxSlides = 2;
                    oSettings.iHolderWidth = iWidowWidth / 1.2;
                    oSettings.slideWidth = (oSettings.iHolderWidth - 8) / 2;
                    oSettings.slideMargin = 8;
                }
                oSettings.rightButtonPos = iWidowWidth - iButtonWidth - 8;
                oSettings.leftButtonPos = -10;
                break;

            case 'tablet':
                if ('LARGE' === sTileSize) {
                    oSettings.minSlides = 1;
                    oSettings.maxSlides = 2;
                    oSettings.iHolderWidth = iWidowWidth / 1.23;
                    oSettings.slideWidth = (oSettings.iHolderWidth - 16) / 2;
                    oSettings.slideMargin = 16;
                }
                else {
                    oSettings.minSlides = oSettings.maxSlides = 4;
                    oSettings.iHolderWidth = iWidowWidth / 1.23;
                    oSettings.slideWidth = (oSettings.iHolderWidth - 48) / 4;
                    oSettings.slideMargin = 16;
                }
                oSettings.rightButtonPos = iWidowWidth - iButtonWidth - 8;
                oSettings.leftButtonPos = -10;
                break;

            case 'desktop':
                if ('LARGE' === sTileSize) {
                    oSettings.minSlides = oSettings.maxSlides = 4;
                    oSettings.iHolderWidth = 960 - 32;
                    oSettings.slideWidth = (oSettings.iHolderWidth - 96) / 4;
                    oSettings.slideMargin = 32;
                }
                else {
                    oSettings.minSlides = oSettings.maxSlides = 6;
                    oSettings.iHolderWidth = 960 - 32;
                    oSettings.slideWidth = (oSettings.iHolderWidth - 160) / 6;
                    oSettings.slideMargin = 32;
                }
                oSettings.rightButtonPos = 960 - 16 - iButtonWidth;
                oSettings.leftButtonPos = -16;
                break;
            }

            return oSettings;
        },



        /**
        * Get the configuration object vor the enterprise version
        * @return {object} - the settings object
        */
        getEnterpriseSettings: function (sTileSize) {
            var iWidowWidth = $(window).width();
            // defines the witdth of the next/prev buttons
            var iButtonWidth = 40;
            var oSettings = {};
            oSettings.easing = 'ease';
            oSettings.infiniteLoop = false;
            oSettings.speed = 400;
            oSettings.hideControlOnEnd = true;

            switch (vf.util.layout(true)) {
            case 'mobile':
                if ('LARGE' === sTileSize) {
                    oSettings.minSlides = oSettings.maxSlides = 1;
                    oSettings.iHolderWidth = iWidowWidth / 1.344;
                    oSettings.slideWidth = oSettings.iHolderWidth - 40;
                    oSettings.slideMargin = 8;
                    oSettings.rightButtonPos = iWidowWidth - iButtonWidth - 20;
                    oSettings.leftButtonPos = -20;
                }
                else {
                    oSettings.minSlides = 1;
                    oSettings.maxSlides = 2;
                    oSettings.iHolderWidth = iWidowWidth / 1.344;
                    oSettings.slideWidth = (oSettings.iHolderWidth - 8) / 2;
                    oSettings.slideMargin = 8;
                    oSettings.rightButtonPos = iWidowWidth - iButtonWidth;
                    oSettings.leftButtonPos = -2;
                }
                break;

            case 'tablet':
                if ('LARGE' === sTileSize) {
                    oSettings.minSlides = oSettings.maxSlides = 2;
                    oSettings.iHolderWidth = iWidowWidth / 1.225;
                    oSettings.slideWidth = (oSettings.iHolderWidth - 16) / 2;
                    oSettings.slideMargin = 16;
                    oSettings.rightButtonPos = iWidowWidth - iButtonWidth - 8;
                    oSettings.leftButtonPos = -10;
                }
                else {
                    oSettings.minSlides = oSettings.maxSlides = 4;
                    oSettings.iHolderWidth = iWidowWidth / 1.23;
                    oSettings.slideWidth = (oSettings.iHolderWidth - 48) / 4;
                    oSettings.slideMargin = 16;
                    oSettings.rightButtonPos = iWidowWidth - iButtonWidth - 8;
                    oSettings.leftButtonPos = -10;

                }

                break;

            case 'desktop':

                oSettings.slideMargin = 32;
                oSettings.rightButtonPos = 960 - iButtonWidth - 48;
                oSettings.leftButtonPos = -16;
                oSettings.iHolderWidth = 960 - 64;

                if ('LARGE' === sTileSize) {
                    oSettings.minSlides = oSettings.maxSlides = 3;
                    oSettings.slideWidth = (oSettings.iHolderWidth - 64) / 3;
                }
                else {
                    oSettings.minSlides = 6;
                    oSettings.maxSlides = 6;
                    oSettings.slideWidth = (oSettings.iHolderWidth - 64) / 6;
                }
                break;
            }

            return oSettings;
        },

        /**
        /**
        * run intro animation
        */
        hideSlidesToTheRight: function (oSliderModel) {

            // move the first 4 elements to the right
            var iOffset = window.innerWidth;
            var numSlides = oSliderModel.oBxSliderInstance[0].childElementCount;

            for (var i = 0; i < numSlides; i++) {
                oSliderModel.oHolderDiv.find('.bxslider li:nth-child(' + (i + 1) + ')').css('margin-left', iOffset);

            }

        },

        scrollHandler: function (oSliderModel) {
            if (null === oSliderModel.oSettings) {
                return;
            }

            var numSlides = oSliderModel.oBxSliderInstance[0].childElementCount;

            if (this.isElementInViewport(oSliderModel.oHolderDiv[0])) {

                var iSpeed = oSliderModel.oSettings.speed / 500;

                for (var i = 0; i < numSlides; i++) {
                    oSliderModel.oHolderDiv.find('.bxslider li:nth-child(' + (i + 1) + ')').css('webkit-transition', 'all ' + iSpeed + 's ease');
                    oSliderModel.oHolderDiv.find('.bxslider li:nth-child(' + (i + 1) + ')').css('transition', 'all ' + iSpeed + 's ease');
                    oSliderModel.oHolderDiv.find('.bxslider li:nth-child(' + (i + 1) + ')').css('margin-left', '0px');
                }

            }

        },


        isElementInViewport: function (el) {

            var rect = el.getBoundingClientRect();
            //return rect.bottom < 0 || rect.right < 0 || rect.left > window.innerWidth || rect.top > window.innerHeight;
            return !(rect.top > window.innerHeight);
        },

    };
}(vf));

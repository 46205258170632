(function () {
    'use strict';
    var settings = {
        target: '.mod-footnotes',
    };

    var openFootnote = function($footnoteTarget) {
        $footnoteTarget.find('input.toggle-input').prop( 'checked', true );
    };

    var closeFootnote = function($footnoteTarget) {
        $footnoteTarget.find('input.toggle-input').prop( 'checked', false );
    };

    var scrollToFootnote = function($footnoteTarget) {
        $('html, body').stop().animate({
            scrollTop: ($footnoteTarget.offset().top - $('.mod-glnav .main-nav').height() - 10 ) + 'px'
        }, 'slow');
    };

    var scrollToFootnoteOverlay = function($overlay, $footnoteTarget) {
        $overlay.find('.overlay-content').stop().animate({
            scrollTop: ($footnoteTarget.position().top - 10) + 'px'
        }, 'slow');
    };

    var init = function () {

        var $sups = $('sup:not(.initialized)');
        var $footnotesWithoutOverlay = $(settings.target).filter(function(){
            return 0 === $(this).parents('.overlay').length;
        });

        $.each( $sups, function( ){
            // dont init twice
            $(this).addClass('initialized');
            // for the get, we need to sub 1 because of the starting 0
            var indexID = $(this).text() - 1;
            // it is in an overlay, so handle it seperatly
            if (0 < $(this).parents('.overlay').length) {
                var $overlay = $(this).parents('.overlay');
                var $footnoteTarget = $($overlay.find(settings.target + ' .collapsible-content li').get(indexID));
                // In case no footnote id will be found, just handle the first footnote
                if (0 === $footnoteTarget.length) {
                    $footnoteTarget = $overlay.find(settings.target).find('label');
                }
                $(this).click(function () {
                    openFootnote($footnoteTarget.parents(settings.target));
                    scrollToFootnoteOverlay($overlay, $footnoteTarget);
                });
            } else {
                var $footnoteTarget = $($footnotesWithoutOverlay.find('.collapsible-content li').get(indexID));
                // In case no footnote id will be found, just handle the first footnote
                if (0 === $footnoteTarget.length) {
                    $footnoteTarget = $footnotesWithoutOverlay.find('label');
                }
                $(this).click(function () {
                    openFootnote($footnoteTarget.parents(settings.target));
                    scrollToFootnote($footnoteTarget);
                });
            }

        });
        // it is just a workaround for now, needs to be implemented better
        // it will handle the sups which are comming on the fly
        // example is the tariff tile
        setTimeout(function() {
            $.each( $('sup.initialized:not(.doubleChecked)'), function( ){
                var isBound = jQuery._data(this, 'events');
                if (undefined === isBound) {
                    $(this).removeClass('initialized');
                    $(this).addClass('doubleChecked');
                    vf.footnotes.init();
                }
            });
        }, 5000);
    };

    vf.footnotes = {
        settings: settings,
        init: init,
        openFootnote: openFootnote,
        closeFootnote: closeFootnote,
        scrollToFootnote: scrollToFootnote
    };

}(vf));



(function () {

    'use strict';

    /**
     * The original vf['footnotes'] has some potential for optimizations ;)
     * vf['footnotes-delegate'] solves exact one problem: It handles @click on <sub> that were not in dom on @ready by delegation these clicks
     */
    vf['footnotes-delegate'] = {
        settings: {
            target: '.footnotes'
        },
        init: function ($context) {

            if (undefined === $context){
                return;
            }

            //var atLeastOneFoonnote = false;
            $context.find('sup').each(function(){
                //var footnoteNumber = $(this).text();

                //var $footnote = $('.mod-footnotes.footnotes ol').find('li').get(footnoteNumber - 1);

                /*if (undefined !== $($footnote).get(0)){
                    atLeastOneFoonnote = true;
                }*/
                $(this).addClass('has-footnote');

            });

            /*if (false === atLeastOneFoonnote){
                return;
            }*/

            $context.on('click', 'sup.has-footnote', function(){

                // open footnotes box, if closed
                if (false === $('.footnotes .tg-head').hasClass('tg-active')) {
                    $('.footnotes .tg-head').trigger('click');
                    $('.footnotes-opened-text').show();
                    $('.footnotes-closed-text').hide();
                }

                var id = $('.mod-footnotes.footnotes ol').find('li').get($(this).text() - 1);

                if (undefined === id) {
                    id = $('.mod-footnotes.footnotes').find('sup:contains("' + $(this).text() + '")').get(0);
                }

                if (undefined === id) {
                    id = $('.mod-footnotes.footnotes').get(0);
                }

                if (0 !== $(this).has('a').length) {
                    id = $(this).children('a').attr('href');
                }

                $('html, body').stop().animate({
                    scrollTop: ($(id).offset().top - $('.mod-glnav .main-nav').height() - 10) + 'px'
                }, 'slow');


            });
        }
    };

}(vf));

(function () {
    vf['enable-inputs'] = {
        settings: {
            target: '.mod-enable-inputs',
            inputWrap: '.enable-input, .disable-input',
            trigger: '.enable-option input',
            groupedInputs: '.enable-input-group'
        },
        init: function (context) {
            var s = this.settings;

            $(s.target, context).addBack(s.target).each(function () {

                $(this).find(s.trigger).on('change', $.proxy(vf['enable-inputs'].toggle, this)).filter(':checked').change();
                $('.disable-input input:not(.enable-option input), .disable-input button, .disable-input select', this).attr('disabled', 'disabled');
                $('.disable-input input[type=checkbox]:not(.enable-option input)', this).next('label').addClass('fm-disabled');
            });
        },

        toggle: function (e) {
            var s = vf['enable-inputs'].settings,
                isGroup = 0 < $(s.groupedInputs, this).length,
                inputWraps = isGroup ? $(s.groupedInputs, this).find(s.inputWrap) : $(e.currentTarget).closest(s.inputWrap),
                force = $('.enable-target', this);
            checked = force.length ? force.is(':checked') : undefined;

            var eBody = $(this).find('.enable-this');
            var fAria = 'true' === eBody.attr('aria-hidden');

            if (fAria) {
                eBody.attr('aria-hidden', 'false');
            } else {
                eBody.attr('aria-hidden', 'true');
            }


            inputWraps.each(function () {
                var inputWrap = $(this),
                    inputs = $('input:not(.enable-option input), button, select', inputWrap);

                inputWrap.toggleClass('enable-input', checked)
					.toggleClass('disable-input', !inputWrap.is('.enable-input'));


                if (inputWrap.hasClass('enable-input')) {
                    inputs.removeAttr('disabled').next('.fm-disabled').removeClass('fm-disabled');
                } else {
                    inputs.attr('disabled', 'disabled');
                    inputs.removeAttr('checked').next('label').addClass('fm-disabled').removeClass('checked');
                }

            });
        }
    };
}(vf));

(function() {
    vf.quicklinks = {
        settings: {
            target: '.mod-quicklinks',
            username: '#ql-username',
            password: '#ql-password',
            box: '.ql-login',
            lastFocus: ''
        },
        init: function(context) {
            var s = this.settings,
                inputs = $('#ql-password, #ql-username');

            $(s.target, context).addBack(s.target).each(function() {
                inputs.bind('focus', $.proxy(vf.quicklinks.open, this));
                inputs.bind('focusout', $.proxy(vf.quicklinks.close, this));
            });
        },
        open: function(e) {
            $(this).addClass('ql-display');
        },
        close: function(e) {
            var s = vf.quicklinks.settings,
                wrap = $(this),
                target = $(e.currentTarget),
                username = wrap.find(s.username),
                password = wrap.find(s.password);

            if ('' === password.val() && '' === username.val()) {
                wrap.removeClass('ql-display');

                    // Focusing out of password input breaks focus due to buttons being hidden
                if (target.is(password) && s.lastFocus == s.username) {
                    wrap.find(s.box).next()
                            .find('a, input')
                            .filter(':visible')
                            .eq(0).focus();
                }
            }

                // Store last focus for toggling backwards
            s.lastFocus = '#' + target.attr('id');
        }
    };
}(vf));

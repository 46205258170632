(function () {
    'use strict';

    vf['glnav-my-vf-login'] = {
        settings: {
            target: '.mod-glnav-my-vf-login',
            usenameFormatValid: false,
            passwordFormatValid: false

        },
        /**
         * init function
         */
        init: function () {
            var me = vf['glnav-my-vf-login'];
            var $inputUsername = $(me.settings.target).find('#input-username');
            var $inputPassword = $(me.settings.target).find('#input-password');

            // init tooltips
            $('.glnav .tg-icon').bind('click', function () {
                var $infoItem = $(this).parent().parent().find('.mod-info-toggle');
                $infoItem.toggleClass('info-active');
                // also adjust the y position if necessary
                var $box = $(this).parent().parent().find('.fm-forminfo-ol-wrapper');
                var regularHeight = 67;
                var actualHight = $box.height();
                if (actualHight > regularHeight){
                    var diff = actualHight - regularHeight;
                    $box.css('top', 'calc(50% - ' + diff / 2 + 'px)' );
                }
            });

            // type handlers
            $inputUsername.bind('input propertychange focusout', function() {
                me.checkUsernameFormat();
                me.checkInputFormat();

            });

            $inputPassword.bind('input propertychange focusout', function() {
                me.checkPasswordFormat();
                me.checkInputFormat();
            });

            // setup sumbit handler
            /*
            $('.mod-glnav-my-vf-login').submit(function( event ) {
                event.preventDefault();
                // testing code
                vf.glnav.setLoginDataVO({
                    userFirstname: 'Maxi',
                    userLastname: 'Muster',
                    lastLoginDateFormatted: '11.11.2011',
                    lastLoginTimeFormatted: '11:11:11'
                });
                vf.overlay.closeOverlay();
                // show backend error
                //$(me.settings.target).find('.alert,.error').css('display', 'block');
            });
            */
        },

                /**
         * check username format
         */
        checkInputFormat: function (){
            var me = vf['glnav-my-vf-login'];

            if (me.settings.usenameFormatValid && me.settings.passwordFormatValid){
                $(me.settings.target).find('.login-btn').removeAttr('disabled');
            } else {
                $(me.settings.target).find('.login-btn').attr('disabled', 'disabled');
            }

        },

        /**
         * check username format
         */
        checkUsernameFormat: function (me){
            var me = vf['glnav-my-vf-login'];
            var $inputUsername = $(me.settings.target).find('#input-username');
            if ('' === $inputUsername.val()){
                $inputUsername.addClass('fm-error-border');
                $inputUsername.parent().parent().find('.error-body').css('display', 'block');
                me.settings.usenameFormatValid = false;
            } else {
                $inputUsername.removeClass('fm-error-border');
                $inputUsername.parent().parent().find('.error-body').css('display', 'none');
                me.settings.usenameFormatValid = true;
            }
        },
        /**
         * check password format
         */
        checkPasswordFormat: function (){
            var me = vf['glnav-my-vf-login'];
            var $inputPassword = $(me.settings.target).find('#input-password');
            if ('' === $inputPassword.val()){
                $inputPassword.addClass('fm-error-border');
                $inputPassword.parent().parent().find('.error-body').css('display', 'block');
                me.settings.passwordFormatValid = false;


            } else {
                $inputPassword.removeClass('fm-error-border');
                $inputPassword.parent().parent().find('.error-body').css('display', 'none');
                me.settings.passwordFormatValid = true;
            }
        },
    };
}(vf));

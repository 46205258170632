(function () {

    'use strict';

    /**
     * This dependens on
     * https://github.com/davidjbradshaw/iframe-resizer
     *
     * The page that contains the iframe needs a script, wich is in core/vendor
     * And the iframe itself has to contain iframeResizer.contentWindow.min.js
     */
    var init = function(){

        var isOldIE = (-1 !== navigator.userAgent.indexOf('MSIE')); // Detect IE10 and below

        iFrameResize({
            enablePublicMethods: true,
            checkOrigin: false,
            heightCalculationMethod: isOldIE ? 'max' : 'lowestElement'
        });

    };

    vf['iframe-resize'] = {
        settings: {
            target: '.mod-iframe-resize'
        },

        init: init
    };

}(vf));

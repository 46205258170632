(function() {

    'use strict';

    var roamingCalculation = function() {
        // defined value for roaming costs per MB
        var roamingCostsPerMB = 0.0595,
            roamingSelectForUsedApp = $('#roamingUsedAppList'),
            roamingMinutesPerDay = $('#roamingUsedMinutes');

        var roamingSelectedAppConsum = 0.00,
            roamingUsedMinutesInTotal = 0.00,
            roamingCostsTotal = 0.00;

        var calculate = function() {
            hideErrorMessage();
            if (canCalculate()) {
                // gets the mid value for the selected value in MB per Minute
                roamingSelectedAppConsum = roamingSelectForUsedApp.find(':selected').data('consum');
                roamingUsedMinutesInTotal = roamingSelectedAppConsum * roamingMinutesPerDay.val();

                // is setted global, it's the total amount of costs for the roaming per day
                roamingCostsTotal = roamingCostsPerMB * roamingUsedMinutesInTotal;
                displayTheCalculatedCosts();
                displayInfoText();

                $('#calculateRoamingCosts').addClass('more-calculation-cta');
                $('#calculateRoamingCosts').val('Weitere Kosten kalkulieren');
            }
        };

        var openSelectIcon = function() {
            $('.select-arrow').addClass('i-arrow-up-grey-lrg');
            $('.select-arrow').removeClass('i-arrow-down-grey-lrg');
        };

        var closeSelectIcon = function() {
            $('.select-arrow').addClass('i-arrow-down-grey-lrg');
            $('.select-arrow').removeClass('i-arrow-up-grey-lrg');
        };

        // On click on the calculate button, start the process
        $('#calculateRoamingCosts').click(calculate);

        $('#roamingUsedAppList').focus(openSelectIcon);
        $('#roamingUsedAppList').blur(closeSelectIcon);
        $('#roamingUsedAppList').change(closeSelectIcon);
        $('#roamingUsedAppList').focusout(closeSelectIcon);


        var displayInfoText = function() {
            if (3.00 <= roamingCostsTotal) {
                $('#roaming-info-wrapper').show();
                $('.fm-to-roaming-sale').show();
            } else {
                $('#roaming-info-wrapper').hide();
                $('.fm-to-roaming-sale').hide();
            }
        };

        // check if all inputs are valid, with some numbers and so on
        var canCalculate = function() {
            var errorFound = false;
            // all valid fields have a value over 1
            if (false === (1 <= roamingSelectForUsedApp.val())) {
                displayErrorMessage($('.error-message-roaming-used-app'), $('#roamingUsedAppList'));
                errorFound = true;
            }

            if (false === ('' !== roamingMinutesPerDay.val())) {
                displayErrorMessage($('.error-message-roaming-used-minutes'), $('#roamingUsedMinutes'));
                errorFound = true;
            }

            if (errorFound) {
                return false;
            }
            return true;
        };

        var displayErrorMessage = function(element, input) {
            element.show();
            input.addClass('error');
        };

        var hideErrorMessage = function() {
            $('.error-message-roaming-used-minutes').hide();
            $('#roamingUsedMinutes').removeClass('error');
            $('.error-message-roaming-used-app').hide();
            $('#roamingUsedAppList').removeClass('error');

            $('.calculated-roaming-wrapper').hide();
            $('#roaming-info-wrapper').hide();
        };

        var displayTheCalculatedCosts = function() {
            // round to 2 decimal after comma and display
            $('#costsForRoaming').html(Math.round((roamingCostsTotal * 100)) / 100 + ' &euro;');
            $('.calculated-roaming-wrapper').show();
        };
    };

    roamingCalculation();

}(vf));

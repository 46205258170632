(function () {
    vf['overview-module'] = {
        init: function (context) {
            var that = this;

            $(window).resize(function () {
                that.reseizeHandler();
            });

            $('.share-button').click(function (e) {
                var oParentDiv = e.currentTarget.parentNode;
                if (!jQuery.data(oParentDiv, 'bOpen')) {
                    that.setPositionsOpen(oParentDiv);
                }
                else {
                    that.setPositionClosed(oParentDiv);
                }
            });

        },

        reseizeHandler: function () {
            var aShareButtponDivs = $('.share');
            for (var i = 0; i < aShareButtponDivs.length; i++) {
                var oDiv = aShareButtponDivs[i];

                if (true == jQuery.data(oDiv, 'bOpen')) {
                    this.setPositionsOpen(oDiv);
                }
            }
        },

        setPositionsOpen: function (oParentDiv) {
            jQuery.data(oParentDiv, 'bOpen', true);
            var iSpeed = '0.3';
            oParentDiv.style.transition = 'all ' + iSpeed + 's ease';
            oParentDiv.style.transition = 'all cubic-bezier(';
            var oButtonContainer = oParentDiv.childNodes[3];
            oButtonContainer.style.transition = 'all ' + iSpeed + 's ease';
            oButtonContainer.style.opacity = 1;
			//oButtonContainer.style.boxShadow = "0px 0px 12px 0px rgba(0, 0, 0, 0.75)";

            if (1025 > window.innerWidth) {
                oParentDiv.style.height = '90px';
                oButtonContainer.style.top = '48px';
                oButtonContainer.style.left = 'calc(50% - 72px)';
            }
            else {
                oParentDiv.style.height = '48px';
                oButtonContainer.style.top = '0px';
                oButtonContainer.style.left = '198px';
            }
        },

        setPositionClosed: function (oParentDiv) {
            jQuery.data(oParentDiv, 'bOpen', false);
            var oButtonContainer = oParentDiv.childNodes[3];
            oButtonContainer.style.opacity = 0;
            oParentDiv.style.height = '48px';
            oButtonContainer.style.top = '0px';
            oButtonContainer.style.left = 'calc(50% - 72px)';
        }
    };
}(vf));

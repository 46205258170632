(function() {

    'use strict';

    var settings = {
        target: '.mod-tariff-enterprise-module'
    };

    var reader;

    var createTariffTile = function($tariffTile){

        var tileLength = $tariffTile.find('.tariff-module-tile').length;

        var layout = vf.util.layout(true);

        var slider = null;
        var slider2 = null;

        var addClassToLastPriceInfo = function(){
            $tariffTile.find('.tariff-module-tile').each(function(){
                var $priceDetails = $(this).find('.details .price-detail');
                var $priceDetail = $( $priceDetails.get( ($priceDetails.length - 1) ) );

                $priceDetail.addClass('last');

            });
        };
        addClassToLastPriceInfo();

        var updateMobileIndicatorPosition = function (newIndex){
            var $tileIndicator = $tariffTile.find('.bx-mobile-indicator');
            var $tileIndicatorInside = $tileIndicator.find('.bx-mobile-indicator-position');
            var pos = $tileIndicatorInside.width() * newIndex;
            $tileIndicatorInside.css('left', pos + 'px' );
        };

        var detectTileLength = function(){

            var tileLengthName = 'one';
            if (2 === tileLength){
                tileLengthName = 'two';
            }
            if (3 === tileLength){
                tileLengthName = 'three';
            }
            if (4 === tileLength){
                tileLengthName = 'four';
            }
            if (5 <= tileLength){
                tileLengthName = 'five';
            }

            var tileLengthNameClass = tileLengthName + '-tiles';

            $tariffTile.parents('.mod-tariff-enterprise-module').addClass(tileLengthNameClass)
                .find('.tariff-module-products').addClass(tileLengthNameClass)
            ;

            $tariffTile.find('.tariff-module-tiles').addClass(tileLengthNameClass);

            if (1 === tileLength || 2 === tileLength){
                $tariffTile.addClass(tileLengthName + '-tiles-wrapper');
            }
        };

        var setPagerActive = function(currentSlide){
            $tariffTile.find('.slidePager .slidePagerItemWrapper a').removeClass('proxy-active');
            $($tariffTile.find('.slidePager .slidePagerItemWrapper a').get(currentSlide)).addClass('proxy-active');
        };

        var hideArrow = function (){
            if ('mobile' === layout){
                var $arrow = $tariffTile.parent().parent().find('.arrow-down');
                $arrow.css({'opacity': '0', 'top': '1000000px'});
            }
        };

        var onSlideBefore = function($slideElement, oldIndex, newIndex){
            // proxy-active
            var currentSlide = slider.getCurrentSlide() + 1;
            if (slider2) {
                slider2.goToSlide(newIndex);
            }
            setPagerActive(currentSlide);
            hideArrow();
            updateMobileIndicatorPosition(newIndex);
        };

        var showArrow = function (){
            var $arrow = $tariffTile.parent().parent().find('.arrow-down');
            $arrow.css({
                'opacity': '1',
                'top': '0px'
            });
        };


        var onSlideAfter = function (){
            var onSlideAfterAttr = $tariffTile.attr('on-slide-after');
            if (onSlideAfterAttr) {
                var $activeTariffItem = $tariffTile.find('.tariff-module-tile.active-eop');
                $(window).trigger('on-slide-after', $activeTariffItem);
            } else {
                var $activeTarifItem = $tariffTile.find('.tariff-module-tile.active');
                var $arrow = $tariffTile.parent().parent().find('.arrow-down');
                if (0 === $activeTarifItem.length){
                    return;
                }
                var offsetActiveItem = $activeTarifItem.offset().left;
                $arrow.css({
                    'left': offsetActiveItem + 'px',
                    'opacity': '1',
                    'top': '0px'
                });
            }

        };



        var getSliderOptions = function(){

            var options = {
                minSlides: 5,
                maxSlides: 3,
                infiniteLoop: false,
                hideControlOnEnd: true,
                slideWidth: 9999,
                slideMargin: 0,
                moveSlides: 1,
                adaptiveHeight: false,
                easing: 'ease',
                speed: 400,
                preloadImages: 'all',
                /*pagerCustom: '.slidePager:last',*/
                onSlideBefore: onSlideBefore,
                onSlideAfter: onSlideAfter
            };

            var minSlidesAttr = $tariffTile.attr('min-slides');
            var touchEnabledAttr = $tariffTile.attr('touch-enabled');
            if (minSlidesAttr) {
                options.minSlides = (('tablet' === layout) ? (5) : (('mobile' === layout) ? (1) : (6)));
            }
            if ('tablet' === layout){
                options.touchEnabled = false;
            }
            if ('mobile' === layout){
                options.minSlides = 1;
                var adjustSlideWidthAttr = $tariffTile.attr('adjust-slide-width');
                if (adjustSlideWidthAttr) {
                    options.slideWidth = parseInt( ($tariffTile.width() - parseInt(adjustSlideWidthAttr)), 10);
                } else {
                    options.slideWidth = parseInt( ($tariffTile.width() - 70 - 2 ), 10);
                }
                options.touchEnabled = (1 < $('div.tariff-module-tile-wrapper').length) ? true : false;
            }
            if ('true' === touchEnabledAttr){
                options.touchEnabled = true;
            }

            return options;
        };
        var getSliderOptions2 = function(){

            var options = {
                minSlides: 3,
                maxSlides: 3,
                infiniteLoop: false,
                hideControlOnEnd: true,
                slideWidth: 9999,
                slideMargin: 0,
                moveSlides: 1,
                adaptiveHeight: false,
                easing: 'ease',
                speed: 400,
                preloadImages: 'all',
                touchEnabled: false,
                /*pagerCustom: '.slidePager:last',*/
                onSlideBefore: onSlideBefore,
                onSlideAfter: onSlideAfter
            };

            var minSlidesAttr = $tariffTile.attr('min-slides');
            //  var touchEnabledAttr = $tariffTile.attr('touch-enabled');
            options.minSlides = ((minSlidesAttr === undefined) ? 5 : parseInt(minSlidesAttr));

            if ('mobile' === layout){
                options.minSlides = 1;
                var adjustSlideWidthAttr = $tariffTile.attr('adjust-slide-width');
                if (adjustSlideWidthAttr) {
                    options.slideWidth = parseInt( ($tariffTile.width() - parseInt(adjustSlideWidthAttr)), 10);
                } else {
                    options.slideWidth = parseInt( ($tariffTile.width() - 70 - 2 ), 10);
                }

            }


            return options;
        };
        var getInfoLayerHeight = function($tile){
            var height = 0;

            // complete height
            height = $tile.outerHeight(true);

            // minus the head
            height = height - $tile.find('.head').outerHeight(true);

            // minus the price
            height = height - $tile.find('.price-detail').outerHeight(true);

            // minus the arrow
            if (0 < $tile.find('.arrow-down').length){
                height = height - $tile.find('.arrow-down').outerHeight(true);
            }

            // height of first border
            height = height + 1;

            return height;
        };

        var getInfoLayerTop = function($tile){
            // -1 because of the first border
            return $tile.find('.head').innerHeight() - 1;
        };

        var updateDirectionControls = function(){
            var hideDirectionControlsAttr = $tariffTile.attr('hide-direction-controls');
            if ('true' === hideDirectionControlsAttr){
                // hide/view navigation elements and indicators
                if (true === vf.util.is_touch_device && 'desktop' !== vf.util.layout(true)) {
                	$tariffTile.find('.bx-next').css('display', 'none');
                	$tariffTile.find('.bx-prev').css('display', 'none');
                }
            }
        };

        /*
                            ** functions for appearance
                            */
        var updateSlider = function(){

            if (slider) {
                slider.reloadSlider(getSliderOptions());
                updateDirectionControls();
            }
            if ('mobile' === layout){
                $tariffTile.find('.detail-wrap').addClass('vertical-divider');
                separateDetails();
            }
            else {
                $tariffTile.find('.detail-wrap').removeClass('vertical-divider');
                prepareJoin();
                joinDetails();
                doJoin();
            }

        };

        var createSlider = function(){
            // only create the slider if therer are more than 1 slides !!
            var numSlides = $tariffTile.find('.tariff-module-tile-wrapper').length;

            if (1 < numSlides ){
                slider = $tariffTile.find('.tariff-module-tiles').bxSlider(getSliderOptions());
                if (1 < $tariffTile.find('.bx-slider').length) {
                    slider2 = $tariffTile.find('.tariff-module-products').bxSlider(getSliderOptions2());
                }
                setPagerActive(1);
                $tariffTile.find('.tariff-module-tiles').bind('touchmove', hideArrow);
                $tariffTile.find('.tariff-module-tiles').bind('touchend', showArrow);
                updateDirectionControls();
            }
            $tariffTile.find('.detail-wrap').find('ul:not(.icon-list)').addClass('bullet-list');

        };

        var positionOpenInfoLayer = function(){

            var $openInfoLayer = $tariffTile.find('.info-layer.opened-layer');

            if (undefined === $openInfoLayer.get(0)){
                return;
            }

            var $tile = $openInfoLayer.parents('.tariff-module-tile');

            $tile.find('.info-layer').css('height', getInfoLayerHeight($tile));
            $tile.find('.info-layer').css('top', getInfoLayerTop($tile));

        };

        var toggleInfoLayer = function($state, $tile){

            $tile.parents('.tariff-module-tiles-wrapper').find('.info-layer').css('display', 'none');

            $tile.parents('.tariff-module-tiles-wrapper').find('.info-layer.opened-layer').removeClass('opened-layer');
            $tile.parents('.tariff-module-tiles-wrapper').find('.info-layer.wider').removeClass('wider');
            $tile.parents('.tariff-module-tiles-wrapper').find('.head.show-as-active').removeClass('show-as-active');
            $tile.parents('.tariff-module-tiles-wrapper').find('.open-info-layer').show();

            if ('open' === $state){
                $tile.find('.info-layer').css('padding', '20px');
                $tile.find('.info-layer').css('border-width', '1px');
                $tile.find('.info-layer').css('display', 'block');
                $tile.find('.info-layer').addClass('opened-layer');
                $tile.find('.open-info-layer').hide();
                if (false === $tile.parents('.tariff-module-tiles-wrapper').hasClass('has_products')){
                    $tile.find('.head').addClass('show-as-active');
                    $tile.find('.info-layer').addClass('wider');
                }
            }

            positionOpenInfoLayer();
        };

        var changeActiveState = function($active){
            var eopTariff = $tariffTile.parents('.eop-tariff');
            if ((eopTariff) && (0 < eopTariff.length)) {
                /* remove old active */
                $tariffTile.find('.active-eop').removeClass('active-eop');
                /* set new active */
                $active.addClass('active-eop');
            } else {
                /* remove old active */
                $tariffTile.find('.active').removeClass('active');
                /* set new active */
                $active.addClass('active');
            }
            // find the index of the active tile

            /* and also sroll the the highligted slide
                                 but only do that on mobiel */
            if ((slider) && 'mobile' === layout){
                slider.goToSlide($active.parents('.tariff-module-tile-wrapper').index());
            }
            $active.parents('.mod-tariff-enterprise-module').trigger('tariff-tiles@activeChanged');

            /* when no active layer open in new active close all info layers */
            if (0 === $active.find('.opened-layer').length){
                toggleInfoLayer('close', $active);
            }

        };

        var setVolumeHeight = function(){
            var $volumes = $tariffTile.find('.volumen');
            var maxHeight = 0;

            $volumes.each(function(index, item){
                if ($(item).height() > maxHeight){
                    maxHeight = $(item).height();
                }
            });

            $volumes.height(maxHeight);

            // if 2 rows, then use 2 rows for all tiles
            if (35 < maxHeight){
                $volumes.find('.volumen-amount').css('margin-right', '40%');
            }
        };

        var setBadgeHeight = function(){
            if ('mobile' === layout){
                if (0 < $tariffTile.find('.badge').length){
                    if (0 === $tariffTile.find('.bx-viewport').length){
                        $tariffTile.css('padding-top', '25px');
                    }
                }
            }
        };

        var detailEqHeight = function(){// eslint-disable-line no-unused-vars

            var maxHeight = [];

            $tariffTile.find('.tariff-module-tile').each(function(){


                var $details = $(this).find('.detail, .price-detail');

                $details.css('height', 'auto');

                $details.each(function(index){

                    var height = $(this).outerHeight();

                    if (undefined === maxHeight[index] || height > maxHeight[index]){
                        maxHeight[index] = height;
                    }

                });

            });

            $tariffTile.find('.tariff-module-tile').each(function(){

                var $details = $(this).find('.detail, .price-detail');

                $details.each(function(index){

                    $(this).css('height', maxHeight[index] + 'px');

                });

            });

        };


        var joinSelectors = '.detail-headline, li, p';

        var prepareJoin = function(){

            var $tiles = $tariffTile.find('.tariff-module-tile');

            $tiles.first().find('.detail').addClass('first');
            $tiles.last().find('.detail').addClass('last');

            $tiles.each(function(){

                $(this).find('.detail-join').each(function(detailIndex){

                    $(this).attr('data-detail-index', detailIndex);

                    $(this).find(joinSelectors).each(function(elementIndex){

                        $(this).attr('data-elem-index', elementIndex);
                        $(this).attr('data-detail-elem-index', detailIndex + '-' + elementIndex);
                        $(this).attr('data-detail-index', detailIndex);

                    });

                });

            });

        };

        var joinDetail = function(detailNumber){

            var $details = [];
            $tariffTile.find('.tariff-module-tile').each(function(){
                $details.push(
                    $(this).find('.detail-join:nth(' + detailNumber + ')')
                );
            });

            $($details).each(function(detailIndex){
                var $startDetail = $details[detailIndex];
                var $startElements = $startDetail.find(joinSelectors);

                // we are at the very last tariff of this module, if the elements are not joined yet, they will never be joined
                if ((detailIndex + 1) >= $details.length ){
                    $startElements.each(function(){

                        if (true === $(this).hasClass('joined')){
                            return true;
                        }

                        $(this).addClass('not-joined');

                        return true;
                    });
                    return false;
                }

                var $compareDetail = $details[ (detailIndex + 1) ];

                var $compareElements = $compareDetail.find(joinSelectors);

                $startElements.each(function(elementIndex){

                    var $startElement = $(this);
                    var $compareElement = $($compareElements.get(elementIndex));

                    if ($startElement.text() === $compareElement.text()){

                        if (undefined === $startElement.data('join')){
                            $startElement.attr('data-join-first', detailIndex);
                            $startElement.attr('data-join-last', $details.length - 1);
                        }

                        $startElement.attr('data-join', true);
                        $compareElement.attr('data-join', true);

                        var joinedBy = $startElement.attr('data-joined-by') || detailIndex;

                        $compareElement.attr('data-joined-by', joinedBy);

                    }
                    else {
                        $startElement.attr('data-not-joined', true);
                        $startElement.addClass('not-joined');
                    }

                    if (true === $startElement.data('join') && $startElement.text() !== $compareElement.text()){
                        var startBy = $startElement.data('joined-by');

                        $($details[startBy].find(joinSelectors).get(elementIndex)).attr('data-join-last', detailIndex);
                    }
                });

                return true;

            });

        };

        var separateDetails = function (){
            if ('mobile' !== layout){
                return;
            }
            $tariffTile.find('.joined').css('width', '100%');
        };

        var joinDetails = function(){
            if ('mobile' === layout){
                return;
            }

            /*
                                 *   the whole joining over tarifs depends on editorial correct tiles ;)
                                 *   e.g. it will fail if $tariffTile.find('.tariff-module-tile:nth(0) .detail-join').length !== $tariffTile.find('.tariff-module-tile:nth(n) .detail-join');
                                 */
            var $details = $tariffTile.find('.tariff-module-tile:nth(0) .detail-join');

            for (var i = 0, x = $details.length; i < x; i += 1){
                joinDetail(i);
            }

            return;

        };

        var doJoin = function(){
            if ('mobile' === layout){
                return;
            }

            var width = $tariffTile.find('.tariff-module-tile .detail-join').width();
            var $details = [];

            $tariffTile.find('.tariff-module-tile').each(function(){
                $details.push(
                    $(this).find('.detail-join:first-of-type')
                );
            });

            var calcWidth = function(start, end){

                var offsetStart = $details[start].offset();
                var offsetEnd = $details[end].offset();

                return offsetEnd.left - offsetStart.left + width;

            };

            $tariffTile.find('.tariff-module-tile .detail-join').find(joinSelectors).each(function(){

                var $elem = $(this);

                var detailElemIndex = $elem.data('detail-elem-index');

                var shouldJoin = false;
                var hasGapTop = false;

                var joinStart = $(this).data('join-first');
                var joinEnd = $(this).data('join-last');

                var elemWidth;

                var $prev = $(this).prev(joinSelectors + ',.proxy');

                if (true === $prev.hasClass('proxy')){
                    $prev = $prev.find(joinSelectors);
                }

                // an unjoined elem or an elem that starts a join
                if (undefined === $elem.data('join') || undefined !== joinStart){

                    if (0 !== $prev.length && undefined !== $prev.data('joined-by')){
                        hasGapTop = true;
                    }

                }

                if (undefined !== joinStart && undefined !== joinEnd){
                    shouldJoin = true;
                }

                if (true === shouldJoin){

                    elemWidth = calcWidth(joinStart, joinEnd);

                    $elem = $('<span>');
                    $elem.addClass('proxy');

                    var $proxy = $(this).clone();

                    $elem.append($proxy);

                    $(this).replaceWith($elem);

                    $proxy.css({
                        width: elemWidth + 'px'
                    });

                    var height = $proxy.outerHeight(true);

                    $elem.css({
                        height: height + 'px'
                    });

                    var $joinElements = $tariffTile.find('[data-detail-elem-index=' + detailElemIndex + ']');

                    $joinElements.addClass('joined').css({
                        height: height + 'px'
                    });

                    if (true === hasGapTop){
                        $joinElements.addClass('gap-top');
                    }

                }

                if (true === hasGapTop){
                    $elem.addClass('gap-top');
                }

            });

        };

        /*
                             * functions to get values
                             */
        var trigger = function(){
            $tariffTile.find('.volumen,.top').click(function(){
                var $isActive = $(this).parent().parent().hasClass( 'active' );
                if ( $isActive){
                    toggleInfoLayer('open', $(this).parents('.tariff-module-tile'));
                }
            });

            $tariffTile.find('.head, .price-detail.last').click(function(){
                changeActiveState($(this).parents('.tariff-module-tile'));
            });

            $tariffTile.find('.open-info-layer').click(function(){
                toggleInfoLayer('open', $(this).parents('.tariff-module-tile'));
            });

            $tariffTile.find('.close-info-layer').click(function(){
                toggleInfoLayer('close', $(this).parents('.tariff-module-tile'));
            });
        };

        /*
                            ** init function
                            */
        var create = function(){

            trigger();

            detectTileLength();

            prepareJoin();
            joinDetails();
            doJoin();
            detailEqHeight();

            createSlider();
            // again, because the slider makes other width
            doJoin();
            if ('mobile' !== layout){
                detailEqHeight();
            }

            setVolumeHeight();
            setBadgeHeight();

        };
        create();

        /*
                            ** change css when change window size
                            */
        $(window).on('vf::resize orientationchange load', function(){

            layout = vf.util.layout(true);
            updateSlider();

            setVolumeHeight();
            setBadgeHeight();
            positionOpenInfoLayer();

            doJoin();
            detailEqHeight();
            updateSlider();
            updateMobileIndicatorPosition(0);
            if (!$tariffTile.attr('no-init-for-footnotes')){
                vf.footnotes.init(document.body);
            }
        });
    };

    var connectToVlux = function($tariffModule){

        var productsBox = function(){

            var updateArrow = function($activeTarifItem){
                var $activeTarifItem = $tariffModule.find('.tariff-module-tile.active');

                var $arrow = $tariffModule.find('.arrow-down');
                var width = $activeTarifItem.width();

                var offsetItem = $activeTarifItem.offset();
                if ('undefined' === typeof offsetItem ){
                    return;
                }
                var offsetBox = $tariffModule.offset();
                var offsetLeft = offsetItem.left - offsetBox.left;

                $arrow.css({
                    'width': width + 'px',
                    'left': offsetLeft + 'px'
                });

                $arrow.find('.arrow-box').css({
                    'border-left': (width / 2) + 'px solid transparent',
                    'border-right': (width / 2) + 'px solid transparent'
                });

                var shadowWidth = Math.sqrt(
                    Math.pow( ( width / 2), 2) + Math.pow(30, 2)
                );

                var shadowDeg = Math.sin(
                    30 / shadowWidth
                ) * (180 / Math.PI);

                $arrow.find('.arrow-shadow.left').css({
                    'width': ( width) + 'px',
                    'margin-left': ( -1 * width / 2) + 'px',
                    'transform': 'rotate(' + shadowDeg + 'deg)'
                });

                $arrow.find('.arrow-shadow.right').css({
                    'width': ( width) + 'px',
                    'margin-left': (width / 2) + 'px',
                    'transform': 'rotate(' + -1 * shadowDeg + 'deg)'
                });

            };

            var updateProductsBox = function(){

                var $activeTarifItem = $tariffModule.find('.tariff-module-tile.active');

                var virtualIds = [];
                $activeTarifItem.find('.tariff-module-tile-product').each(function(){

                    var virtualId = $(this).text();
                    if ('' !== virtualId){
                        virtualIds.push(virtualId);
                    }
                });

                // works only for tree devices
                if ([] === virtualIds || 3 !== virtualIds.length){
                    return;
                }

                updateArrow();

                var sku = $activeTarifItem.data('sku');

                var tariff = reader.getTarifData(sku);

                $tariffModule.find('.tariff-module-products-subheading span').text(tariff.name);

                $tariffModule.find('.tariff-module-products .action-box').each(function(index){
                    var $deviceItem = $(this);

                    var virtualId = virtualIds[index];

                    var data = reader.getDeviceDataByVirtualId(sku, virtualId);

                    $deviceItem.find('.device_name').html(data.name);
                    $deviceItem.find('.img-wrap img').attr('src', data.imageSrc);
                    $deviceItem.find('.price span').html(data.price);
                    $deviceItem.find('.tariff_name span').html(tariff.name);

                    return true;

                });


                //@TODO eq-height should consume two events: eq-height:initByEvent and eq-height:recalc
                // One to trigger init and one to trigger a resize e.g. if devicename change from two to onelined
                $tariffModule.find('.tariff-module-products .mod-eq-height').trigger('eq-height:update');


            };

            $tariffModule.on('tariff-tiles@activeChanged', function(){
                updateProductsBox();
            });

            updateProductsBox();

            $(window).on('vf::resize vf::lazyloaded orientationchange vf::fontLoaded click', updateArrow);

        };

        var addVluxDataToTarif = function($tarifItem){

            var sku = $tarifItem.data('sku');

            $tarifItem.find('[data-virtualid]').each(function(){

                var $deviceItem = $(this);

                var virtualid = $deviceItem.data('virtualid');

                if (undefined === virtualid){
                    return true;
                }

                $deviceItem.find('.btn').each(function(){
                    var simonly = $(this).hasClass('simonly');

                    $(this).attr('href', reader.getTariffShopLink(sku, simonly));

                });

                var data;

                if ('simonly' === virtualid){

                    data = reader.getTarifData(sku);

                }
                else {

                    data = reader.getDeviceDataByVirtualId(sku, virtualid);

                }

                var $price = $deviceItem.find('.price span');

                if ('' === $price.text()){
                    $price.text(data.monthlyCharge);
                }

                return true;

            });


        };

        var enableTariffTileDialog = function($tarifItem){

            var sku = $tarifItem.data('sku');

            var tariff = reader.getTarifData(sku);
            $tarifItem.find('.tariffdetails').each(function(){
                if ('' === $(this).attr('href')){
                    $(this).attr('href', tariff.detailLink);
                }
            });

        };

        var addVluxDataToTariffModule = function(){

            $tariffModule.find('[data-sku]').each(function(){
                enableTariffTileDialog($(this));
                addVluxDataToTarif($(this));
            });

            if (true === $tariffModule.hasClass('has_products')){
                productsBox();
            }

        };

        addVluxDataToTariffModule();
    };

    var init = function(){
        $(settings.target + ' .tariff-module-tiles-wrapper').each(function(){
            var initDoneAttr = $(this).attr('init');
            if ('true' !== initDoneAttr) {
                createTariffTile($(this));
                $(this).attr('init', 'true');
            }
        });

        var $tariffModulesWithVlux = $(settings.target + '[data-vlux]');

        if (undefined !== $tariffModulesWithVlux.get(0)){

            reader = vf.connect.getReader();

            reader.initialise()
                .then(function(){

                    $tariffModulesWithVlux.each(function(){
                        connectToVlux($(this));
                    });
                    // trigger lazyloaded
                    $(window).trigger('vf::lazyloaded');
                });

        }
    };

    vf['tariff-enterprise-module'] = {
        settings: settings,
        init: init
    };
}(vf));

(function() {

    'use strict';

    vf['device-tariff-tiles'] = {

        vluxApi: null,
        vluxProductsVoice: null,
        vluxProductsData: null,
        vluxOffer: null,


        settings: {
            target: '.mod-device-tariff-tiles',
            globalApiPathname: '/api-static/ng/ecommerce',
            globalApiPathnameStatic: '/api-static/ng/ecommerce'
        },

        init: function(context) {

            var s = this.settings;

            $(s.target, context).addBack(s.target).each(function() {

                var parentElement = $(this),
                    me = vf['device-tariff-tiles'];

                var eCommerceContext = {
                    btx: parentElement.data('btx') || 'newContract',
                    salesChannel: parentElement.data('saleschannel') || 'Online.Consumer'
                };

                me.vluxApi = new vf.VluxAPI({
                    'pathname': s.globalApiPathname,
                    'pathnameStatic': s.globalApiPathnameStatic
                });

                me.vluxProductsVoice = new vf.VluxProducts(me.vluxApi);
                me.vluxProductsData = new vf.VluxProducts(me.vluxApi);
                me.vluxSubscriptions = new vf.VluxSubscriptions(me.vluxApi, 'mobile');
                me.vluxOffers = new vf.VluxOffers(me.vluxApi, me.vluxSubscriptions, eCommerceContext);

                $.when(me.vluxSubscriptions.loadData(), me.vluxOffers.loadData(), me.vluxProductsVoice.loadData('global'), me.vluxProductsData.loadData('data')).done(function() {
                    parentElement.find('.phoneContainer').each(function() {
                        vf['device-tariff-tiles'].executeTile($(this));
                    });
                });
            });

        },

        executeTile: function (item, tariffId, deviceId) {

            var deviceId = item.data('vlux-deviceid').toString(),
                tariffId = item.data('vlux-tariffid').toString(),
                found = false;

            if (deviceId && tariffId) {
                var offer = this.vluxOffers.getOfferByTariffAndDeviceId(tariffId, deviceId);
                if (offer) {
                    found = true;
                    var device = this.vluxProductsVoice.getDeviceById(deviceId) || this.vluxProductsData.getDeviceById(deviceId),
                        virtual = this.vluxProductsVoice.getVirtualDeviceById(deviceId) || this.vluxProductsData.getVirtualDeviceById(deviceId),
                        costs = this.vluxOffers.getFee(offer);
                    if (device && costs) {

                        /* Device */
                        $(item).find('.phoneBox').addClass(this.vluxProductsVoice.getDeviceType(virtual) || '');
                        $(item).find('h3').html(virtual.label);
                        $(item).find('img').attr('src', this.vluxProductsData.getImageByProduct(device, 'small'));
                        $(item).find('img').attr('alt', device.label);
                        $(item).find('.tariffName').html(this.vluxSubscriptions.getTariffById(tariffId).label);
                        $(item).find('.devicePrice').html(parseFloat(costs.deviceFee.devicePrice).toFixed(2).replace('.', ',') || '');

                        if (costs.deviceFee.strikePrice > costs.deviceFee.devicePrice) {
                            $(item).find('.deviceStrikePrice').html(parseFloat(costs.deviceFee.strikePrice).toFixed(2).replace('.', ',') || '');
                            $(item).find('.strikePrice').show();
                        } else {
                            $(item).find('.strikePrice').hide();
                        }

                        /* Tarifpreis */
                        var tariffPriceDiscount = parseFloat(costs.tariffFee.tariffFee) - parseFloat(costs.tariffFee.serviceFee);
                        $(item).find('.tariffPriceDiscount').html( tariffPriceDiscount.toFixed(2).replace('.', ',') || '');

                        /* Text "ab dem x. Monat */
                        if (0 < costs.tariffFee.month) {
                            var serviceFeeAfterLimit = parseFloat(costs.tariffFee.serviceFee) - parseFloat(costs.tariffFee.serviceFeeLimited);
                            var tariffPriceRegular = parseFloat(costs.tariffFee.tariffFee) - serviceFeeAfterLimit;
                            $(item).find('.monthDate').html(costs.tariffFee.month + 1);
                            $(item).find('.tariffPriceRegular').html(tariffPriceRegular.toFixed(2).replace('.', ',') || '');
                            $(item).find('.monthUntil').show();
                        } else {
                            $(item).find('.monthUntil').hide();
                        }
                    }
                }
            }

            if (!found) {
                $(item).remove();
            }
        }
    };

}(vf));

(function () {
    'use strict';
    vf.glnav = {
        settings: {
            target: '.mod-glnav',
            isInMobileOrTabletViewPort: null,
            loginDataVO: null,
            mobilmenuOpendInOrderToShowMyVfFlag: false,
            showFlyoutAgianFlag: false
        },
        /**
         * init function
         */
        init: function (context) {
            // init settings
            var s = vf.glnav.settings;
            vf.glnav.settings.isInMobileOrTabletViewPort = vf.glnav.isTabletOrMobile();

            vf.glnav.setLoginDataVO(null);

            var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

            $(s.target, context).addBack(s.target).each(function () {
                // legacy sicky pricebox patc
                var mainNavTop = $(s.target).find('.main-nav').offset().top;
                // sticky header
                vf.glnav.hasLegcySticky();
                $(window).scroll(function () {
                    if ($(window).scrollTop() > mainNavTop && !vf.glnav.hasLegcySticky()) {
                        if ((!1 === $('.ebpaBody').length) && (0 < (document.location.href.indexOf('/proxy42')) || 0 < (document.location.href.indexOf('/contact')))) {
                            $(s.target).find('.main-nav').css({ position: 'fixed', top: '0px', left: '0px', width: '1230px' });
                        } else {
                            $(s.target).find('.main-nav').css({ position: 'fixed', top: '0px' });
                        }
                        $(s.target).find('ul.list-nav-main').removeClass('scrollweg');
                    } else {
                        $(s.target).find('.main-nav').css({ position: 'relative', top: '0px' });

                        if (0 !== $(window).scrollTop() && vf.glnav.hasLegcySticky()) {
                            $(s.target).find('ul.list-nav-main').addClass('scrollweg');
                        }
                    }
                });

                // custom handlers for myVodafone button
                $(s.target).find('.open-overlay-my-vf').bind('mouseenter', function (event) {
                    event.preventDefault();
                    if (vf.glnav.getLoginDataVO() && !supportsTouch) {
                        $(s.target).find('.myvf-flyout').addClass('nav-display');
                    }
                });
                $(s.target).find('.main-nav .list-nav-right').bind('mouseleave', function (event) {
                    if (vf.glnav.getLoginDataVO()) {
                        $(s.target).find('.myvf-flyout').removeClass('nav-display');
                    }
                });
                $(s.target).find('.open-overlay-my-vf').bind('click', function (event) {
                    event.preventDefault();
                    if (!vf.glnav.getLoginDataVO() || (vf.glnav.getLoginDataVO() && supportsTouch)) {
                        vf.glnav.myVodafoneButtonHandler();
                    }
                });

                // menu button click
                $(s.target).find('.item-menu ').click(function () {
                    $(this).parent().parent().parent().toggleClass('nav-display');
                    //$('.glnav-tab-wrapper .btn').addClass('btn-sml'); if menu visible
                    if ($(this).parent().parent().parent().hasClass('nav-display')){
                        $(this).parent().parent().parent().find('ul.list-nav-main').css('-webkit-overflow-scrolling', 'touch');
                        // add bg
                        $('.mod-glnav .main-nav.nav-display .belt').prepend( '<div class="list-bg" id="glnavListBg"></div>');

                        $('.mod-glnav .myvf-flyout').removeClass('nav-display');
                    } else {
                        $(this).parent().parent().parent().find('ul.list-nav-main').css('-webkit-overflow-scrolling', 'auto');
                        // remove div
                        $('#glnavListBg').remove();
                    }
                    vf.glnav.setMenuBgHeight();
                });

                // meta-nav

                $(s.target).find('.list-nav-meta .meta-sub-trigger').bind('mouseenter', function (event) {
                    $(this).addClass('nav-display');
                });
                $(s.target).find('.list-nav-meta .meta-sub-trigger').bind('mouseleave', function (event) {
                    $(this).removeClass('nav-display');
                });

                // main buttons (kontakt, search, help, shopping basket, login)
                $(s.target).find('.nav-item-main-right').bind('click', function (event) {
                    $('.glnav-tab-wrapper .mod-tabs ul.mod-tabs-nav li:first-child').addClass('active');

                    $('.glnav-tab-wrapper .mod-tabs #registrierung,#quicklinks,#info-app,#contact').addClass('active');
                    $('.glnav-tab-wrapper .mod-tabs #registrierung,#quicklinks,#info-app,#contact').css('display', 'none');

                    $('.glnav-tab-wrapper .mod-tabs #myvf,#hotline').addClass('active');
                    $('.glnav-tab-wrapper .mod-tabs #myvf,#hotline').css('display', 'block');
                    // set the focus in case of the search button
                    if ('searchOverlay' === $(this).find('.open-overlay').attr('data-overlayid')) {
                        $('.mod-overlay.glnav #search-q').focus();
                    }
                    $(window).trigger('resize');

                    if ($(event.currentTarget).hasClass('item-contact')){
                        var phoneNumber = $('.glnav .glnav-tab-wrapper #hotline .btn:first').attr('href');
                        var numberHtml = $('.glnav .glnav-tab-wrapper #hotline .btn:first').html();
                        var markupString = '<a href="' + phoneNumber + '" class="btn">' + numberHtml + '</a>'; $('.glnav #hotline .btn:first').remove();
                        $('.glnav .glnav-tab-wrapper #hotline .text-only:first').after(markupString);
                        // TODO: this has to be removed
                    }
                });

                // adversitement
                $('.glnav-tab-wrapper .mod-tabs ul.mod-tabs-nav li').bind('click', function () {
                    if ('#quicklinks' === $(this).find('a').attr('href')){
                        vf.glnav.ddqPush('quicklinks');
                    }
                });


                // reset tabs if overlay closes
                $(window).bind('vf::overlayclosed', function () {
                    $('.mod-overlay.glnav .mod-tabs-nav li').removeClass('active');
                    $('.mod-overlay.glnav .search-box .se-input').val('');
                    $('.mod-overlay.glnav #mdd-login-form #input-username').val('');
                    $('.mod-overlay.glnav #mdd-login-form #input-password').val('');

                    // close the menu if it was opend from the mobile myVF overlay
                    if (vf.glnav.settings.mobilmenuOpendInOrderToShowMyVfFlag) {
                        $('.mod-glnav .main-nav').removeClass('nav-display');
                        vf.glnav.settings.mobilmenuOpendInOrderToShowMyVfFlag = false;
                    } else {
                        $('.mod-glnav .main-nav ul.list-nav-main').css('-webkit-overflow-scrolling', 'touch');
                    }

                    //Show flyout when myvf overlay is closed
                    if ( vf.glnav.settings.showFlyoutAgianFlag ) {
                        $('.chatFlyout').show();
                    }

                    //Show pricebox when myvf overlay is closed
                    $('#nsf-pricebox').show();
                });



                // window reseize event
                $(window).bind('resize', function (event) {
                    vf.glnav.resizeHandler();
                });

                // search all button
                $('.mod-overlay.glnav .search-all-button').bind('click', function (event) {
                    event.preventDefault();
                    $('.mod-overlay.glnav .search-box').submit();
                });

                // update the login indicator in single page apps
                document.addEventListener('login-status-change', function (e) {
                    vf.glnav.updateLoginIndicator();
                }, false);

                // set up event handlers
                // 1st level
                $(s.target).find('.nav-item-main > .chevron').bind('click', function (event) {
                    vf.glnav.interactionHandler(this, false);
                });

                if (!supportsTouch) {
                    // desktop no touch
                    $(s.target).find('.nav-item-main').bind('mouseenter', function (event) {
                        vf.glnav.interactionHandler(this, false, 'show');
                    });
                    $(s.target).find('.nav-item-main').bind('mouseleave', function (event) {
                        vf.glnav.interactionHandler(this, false, 'hide');
                    });

                } else {
                    // touch device
                    $(s.target).find('.nav-item-main').bind('click', function (event) {
                        // large touch
                        if (!vf.glnav.isTabletOrMobile()) {


                            if (!$(this).find('.nav-secondary').hasClass('nav-display')) {
                                event.preventDefault();

                                $(s.target).find('.nav-secondary').removeClass('nav-display');
                                $(this).find('.nav-secondary').addClass('nav-display');

                                $(s.target).find('.nav-item-main').removeClass('item-main-active');
                                $(this).addClass('item-main-active');
                            }
                        }
                    });
                    if (!vf.glnav.isTabletOrMobile()) {
                        // close menu for large touch device
                        $('body').bind('click', function (event) {
                            $(s.target).find('.nav-secondary, .nav-secondary-sub').removeClass('nav-display');
                            $(s.target).find('.nav-item-main').removeClass('item-main-active');
                            $(s.target).find('.nav-item-sec').removeClass('item-sub-active');
                        });
                        $(s.target).click(function (event) {
                            event.stopPropagation();
                        });
                        $(window).scroll(function () {
                            $(s.target).find('.nav-secondary, .nav-secondary-sub').removeClass('nav-display');
                            $(s.target).find('.nav-item-main').removeClass('item-main-active');
                            $(s.target).find('.nav-item-sec').removeClass('item-sub-active');
                        });
                    }
                }

                // 2nd level
                $('.nav-item-sec > .chevron').bind('click', function (event) {
                    vf.glnav.interactionHandler(this, true);
                });

                if (!supportsTouch) {
                    $(s.target).find('.nav-item-sec').bind('mouseenter', function (event) {
                        vf.glnav.interactionHandler(this, true, 'show');
                    });
                    $(s.target).find('.nav-item-sec').bind('mouseleave', function (event) {
                        vf.glnav.interactionHandler(this, true, 'hide');
                    });
                } else {
                    $(s.target).find('.nav-item-sec').bind('click', function (event) {
                        var numSubItems = $(this).find('.list-sec-sub li').length;
                        if (!vf.glnav.isTabletOrMobile()) {
                            if (!$(this).find('.nav-secondary-sub').hasClass('nav-display') && 0 < numSubItems) {
                                event.preventDefault();
                                $(s.target).find('.nav-secondary-sub').removeClass('nav-display');
                                $(this).find('.nav-secondary-sub').addClass('nav-display');
                                $(s.target).find('.nav-item-sec').removeClass('item-sub-active');
                                $(this).toggleClass('item-sub-active');
                            }
                        }

                    });
                }

                // document ready
                $(document).ready(function () {

                    // read the login cookie
                    vf.glnav.updateLoginIndicator();
                    // in case of enterprise
                    var mode = (undefined === $(s.target).parents('.enterprise').get(0)) ? 'consumer' : 'enterprise';
                    if ('enterprise' === mode) {
                        vf.glnav.setShoppingChartItemCounter(window.getEntCartCount());
                    } else {
                        // set default shopping chart counter
                        vf.glnav.setShoppingChartItemCounter(window.getCartCount());
                    }

                    var myOffset = $('.mod-glnav .main-nav').height() + 10;

                    if (location.hash){
                        try {
                            var offsetA = $(location.hash).offset();
                            var scrolltoA = offsetA.top - myOffset;
                            $('html, body').stop().animate({scrollTop: scrolltoA}, 'slow');
                        } catch (e) {}
                    }

                    var anchors = $.find('a[href^=\'#\']');
                    $.each(anchors, function (index, element) {
                        var anchorId = $(element).attr('href');
                        // Only register click event when href contains a real anchor and said anchor is not within an overlay or tabs
                        if (anchorId.substring(1).length && $(anchorId).length && 0 === $(element).parents('.overlay-content').length && 0 === $(element).parents('.mod-tabs').length && 0 === $(element).parents('.divTabbedNavigationDynHUB').length) {
                            $(element).on('click', function (event) {
                                event.stopPropagation();
                                event.preventDefault();
                                var offset = $(anchorId).offset();
                                var scrollto = offset.top - myOffset;
                                $('html, body').stop().animate({scrollTop: scrollto}, 'slow');
                            });
                        }
                    });
                });
            });
        },

        /**
         * set menu bg height
         */
        setMenuBgHeight: function (){
            var minHeight = window.innerHeight + 120;
            //var menuHeight = $('.mod-glnav .main-nav ul.list-nav-main')[0].scrollHeight + 50;
            //var myHeight = Math.max(minHeight, menuHeight) + 'px';
            $('#glnavListBg').css('height', minHeight);
        },

        /**
         * in case there is a sticky price box - set the margin-top of the body
         * return {bool} either true or false.
         */
        hasLegcySticky: function () {
            //&& 0 < $('.wrapper.noMDD').length
            var pageWithLegacyPriceBox = 0 < $('.stickyBox').length && 768 > screen.width;
            var checkoutPage = 0 < $('.noMDD .total-costs').length;

            if (pageWithLegacyPriceBox || checkoutPage) {
                if (pageWithLegacyPriceBox && !checkoutPage) {
                    $('body').css('margin-top', '44px');
                }
                return true;
            } else {
                return false;
            }
        },


        /**
         * make sure everything is reset to avoid navigation errors
         */
        resizeHandler: function () {

            if (vf.glnav.settings.isInMobileOrTabletViewPort !== vf.glnav.isTabletOrMobile()) {
                var s = vf.glnav.settings;
                $(s.target).find('.nav-item-main').removeClass('item-main-active');
                $(s.target).find('.nav-item-main.selected').addClass('item-main-active');

                $(s.target).find('.nav-item-sec').removeClass('item-sec-active');
                $(s.target).find('.nav-item-sec.selected').addClass('item-sec-active');

                // desktop
                if (!vf.glnav.isTabletOrMobile()) {
                    $(s.target).find('.nav-item-main').find('.nav-secondary').animate({ height: 'show' }, 1);
                    $(s.target).find('.nav-item-sec').find('.nav-secondary-sub').animate({ height: 'show' }, 1);

                    $(s.target).find('.nav-item-main').find('.nav-secondary').removeClass('nav-display');
                    $(s.target).find('.nav-item-sec').find('.nav-secondary-sub').removeClass('nav-display');

                } // tablet/mobile
                else {
                    $('.nav-item-main:not(.selected)').find('.nav-secondary').animate({ height: 'hide' }, 1);
                    $('.nav-item-sec:not(.selected)').find('.nav-secondary-sub').animate({ height: 'hide' }, 1);
                }
                vf.glnav.settings.isInMobileOrTabletViewPort = vf.glnav.isTabletOrMobile();
            }
        },



        /**
         * this function is triggered on mouse enter (desktop) or click (touch device)
         * @param {string} targetRef - the string reference to the link element (either the link or the arrow).
         * @param {string} fromSecondLevel - defines if the event comes from first or second level
         * @param {string} mode - optional value either toggle(default), show ore hide         *
         */
        interactionHandler: function (targetRef, fromSecondLevel, mode) {
            if (mode === undefined) {
                mode = 'toggle';
            }
            var $myTarget;
            // click event on the arrow
            if ($(targetRef).hasClass('chevron')) {
                $myTarget = $(targetRef).parent();
            } else {
                // mouse move event
                // cancel if mobile
                if (vf.glnav.isTabletOrMobile()) { return; }
                $myTarget = $(targetRef);
            }
            if (!fromSecondLevel) {
                vf.glnav.adjustMenu($myTarget, 'item-main-active', '.nav-secondary', mode);
            } else {
                vf.glnav.adjustMenu($myTarget, 'item-sec-active', '.nav-secondary-sub', mode);
            }
            //setTimeout(vf.glnav.setMenuBgHeight(), 500);
        },


        /**
         * ultility function for above
         * @param {object} $myTarget - the jquery object to be modified
         * @param {string} selectedClassName - the class name of the mobile menu to be animated (mobile only)
         * @param {string} secNavClassName - the class name of the navigation below
         */
        adjustMenu: function ($myTarget, selectedClassName, secNavClassName, mode) {

            // for desktop
            if (!vf.glnav.isTabletOrMobile()) {
                if ('toggle' === mode) {
                    $myTarget.find(secNavClassName).toggleClass('nav-display');
                    if ($myTarget.find(secNavClassName).hasClass('nav-display')) {
                        $myTarget.addClass('item-main-active');
                    } else {
                        $myTarget.removeClass('item-main-active');
                    }
                    if (0 < $myTarget.find('.list-sec-sub li').length) {
                        $myTarget.toggleClass('item-sub-active');
                    };
                } else {
                    if ('show' === mode) {
                        $myTarget.addClass('item-main-active');
                        $myTarget.find(secNavClassName).addClass('nav-display');
                        if (0 < $myTarget.find('.list-sec-sub li').length) {
                            $myTarget.addClass('item-sub-active');
                        };
                    } else {
                        $myTarget.removeClass('item-main-active');
                        $myTarget.find(secNavClassName).removeClass('nav-display');
                        if (0 < $myTarget.find('.list-sec-sub li').length) {
                            $myTarget.removeClass('item-sub-active');
                        };
                    }
                }
            }
            // mobile
            else {
                $myTarget.toggleClass(selectedClassName);
                $myTarget.find(secNavClassName).animate({ height: 'toggle' }, 500);
            }

        },

        /**
         * utility function
         * @return {bool} either true or false.
         */
        isTabletOrMobile: function () {
            if (1025 <= window.innerWidth) {
                return false;
            } else {
                return true;
            }
        },

        /**
         * myVodafone button handler
         */
        myVodafoneButtonHandler: function () {
            if (!vf.glnav.getLoginDataVO()) {

                $('.mod-glnav .main-nav ul.list-nav-main').css('-webkit-overflow-scrolling', 'auto');
                // open menu to show myVodafone overlay
                if (vf.glnav.isTabletOrMobile() && !$('.mod-glnav .main-nav').hasClass('nav-display')) {
                    $('.mod-glnav .main-nav').addClass('nav-display');
                    vf.glnav.settings.mobilmenuOpendInOrderToShowMyVfFlag = true;
                }
                vf.overlay.openOverlayDirect('meinVodafoneOverlay');
                // do some advertitment things
                vf.glnav.ddqPush('login');
                // Hide flyout when myvf overlay is open
                if (0 < $('.chatFlyout').length && 'none' !== $('.chatFlyout').css('display') ){
                    vf.glnav.settings.showFlyoutAgianFlag = true;
                } else {
                    vf.glnav.settings.showFlyoutAgianFlag = false;
                }
                $('.chatFlyout').hide();

                //Hide pricebox when myvf overlay is open
                $('#nsf-pricebox').hide();
            } else {
                $('.mod-glnav .myvf-flyout').toggleClass('nav-display');
                // hide the menu in case it is open
                $('.mod-glnav .main-nav').removeClass('nav-display');
                $('#glnavListBg').remove();
            }
        },
        /**
         * ddq push
         * @param {string} myId - either 'login' or 'quicklinks'
         */
        ddqPush: function (myId) {
            if ('undefined' !== typeof _ddq && 'function' === typeof _ddq.push){
                if ('undefined' === typeof vf.glnav.ddqPushFlags) {
                    vf.glnav.ddqPushFlags = { login: false, quicklinks: false };
                }
                if (!vf.glnav.ddqPushFlags[myId]) {
                    var myName = 'mein vodafone login:' + myId;
                    _ddq.push(['layover', { action: 'show', name: myName }]);
                    vf.glnav.ddqPushFlags[myId] = true;
                }
            }
        },


        /**
         * user logged in getter and setter
         * @return {bool} either true or false.
         * @return {object} Object containing user name and login date
         */
        getLoginDataVO: function () {
            if ('undefined' !== typeof vf.glnav.settings.loginDataVO) {
                return vf.glnav.settings.loginDataVO;
            }
            else {
                return false;
            }
        },
        /**
         * @param {object} loginDataVO - Object containing user name and login date
         * userFirstname
         * userLastname
         * lastLoginDateFormatted
         * lastLoginTimeFormatted
         *
         * * @return {object} loginDataVO
         */
        setLoginDataVO: function (loginDataVO) {
            vf.glnav.settings.loginDataVO = loginDataVO;
            if ('undefined' !== typeof vf.glnav.settings.loginDataVO && null !== vf.glnav.settings.loginDataVO) {
                $('.mod-glnav .item-myvf .indicator').css('display', 'block');
                // poulate the user info
                $('.mod-glnav .myvf-flyout .h5').html('Sie sind eingeloggt');
                $('.mod-glnav .myvf-flyout .login-date').html(loginDataVO.lastLoginDateFormatted);
                $('.mod-glnav .myvf-flyout .login-time').html('um ' + loginDataVO.lastLoginTimeFormatted);
            } else {
                $('.mod-glnav .item-myvf .indicator').css('display', 'none');
                $('.mod-glnav .myvf-flyout').removeClass('nav-display');
            }
            return vf.glnav.settings.loginDataVO;
        },

        /**
         * Reads login cookie
         * @return {object} cookieData - object containing  username  and login date
        */
        getLoginCookieData: function () {
            var cookieDataStr = $.cookie('MDDKeks');
            if (cookieDataStr && $.cookie('authHint')) {
                var cookieData = $.parseJSON(cookieDataStr);
                var formatedDateAndTime = this.getDateAndTimeFromTimestamp(parseInt(cookieData.lastLoginDate));
                cookieData.lastLoginDate = formatedDateAndTime.formatedDate;
                cookieData.lastLoginTime = formatedDateAndTime.formatedTime;
                var lt = /</g,
                    gt = />/g,
                    ap = /'/g,
                    ic = /"/g,
                    amp = /&/g;
                cookieData.userFirstname = cookieData.userFirstname.toString().replace(amp, '&amp;').replace(lt, '&lt;').replace(gt, '&gt;').replace(ap, '&#39;').replace(ic, '&#34;');
                cookieData.userLastname = cookieData.userLastname.toString().replace(amp, '&amp;').replace(lt, '&lt;').replace(gt, '&gt;').replace(ap, '&#39;').replace(ic, '&#34;');

                return cookieData;
            }
            return false;
        },

        /**
         * update login indicator
         */
        updateLoginIndicator: function () {
            var loginCookieData = vf.glnav.getLoginCookieData();
            if (loginCookieData) {
                vf.glnav.setLoginDataVO({
                    userFirstname: loginCookieData.userFirstname,
                    userLastname: loginCookieData.userLastname,
                    lastLoginDateFormatted: loginCookieData.lastLoginDate,
                    lastLoginTimeFormatted: loginCookieData.lastLoginTime
                });
            } else {
                vf.glnav.setLoginDataVO(null);
            }
        },

        /**
         * Utility function for above
         * @param {int} timestamp - Object containing user name and login date
         * @return {object} retrunObject - obejct containing formated date and time
         */
        getDateAndTimeFromTimestamp: function (timestamp) {
            var myDate = new Date(timestamp);
            var addZeroIfRequired = function (val) {
                return (10 > val ? ('0' + val.toString()) : val.toString());
            };
            var retrunObject = {};
            retrunObject.formatedDate = addZeroIfRequired(myDate.getDate()) + '.' + addZeroIfRequired(myDate.getMonth() + 1) + '.' + addZeroIfRequired(myDate.getFullYear());
            retrunObject.formatedTime = addZeroIfRequired(myDate.getHours()) + ':' + addZeroIfRequired(myDate.getMinutes()) + ':' + addZeroIfRequired(myDate.getSeconds());
            return retrunObject;
        },

        /**
         * @param {int} itemsCounter - number of items
         */
        setShoppingChartItemCounter: function (numberOfItems) {
            if (0 < numberOfItems) {
                $('.mod-glnav .counter').text(numberOfItems);
                $('.mod-glnav .counter').css('display', 'block');
            } else {
                $('.mod-glnav .counter').css('display', 'none');
            }
        },
    };
}(vf));

/**
 * @component countdown
 * @author Dennis Schnelle
 *
 * Javascripts
 */

(function() {
    'use strict';

    var currentTime = false;

    $(document).ready(function() {

        var vfCountDownMobile = function () {
            if (600 > $(window).width()) {
                $('.countdown').each(function() {
                    $(this).appendTo($(this).parents('.mod-countdown').find('.content-wrap'));
                });
            }
        };

        var vfCountDownDesktop = function () {
            if (600 <= $(window).width()) {
                $('.countdown').each(function() {
                    $(this).appendTo($(this).parents('.mod-countdown'));
                });
            }

            if (600 <= $(window).width() && 900 > $(window).width()) {
                $('.mod-countdown .content-wrap p').ellipsis({
                    lines: 4,             // force ellipsis after a certain number of lines. Default is 'auto'
                    ellipClass: 'ellip',  // class used for ellipsis wrapper and to namespace ellip line
                    responsive: true      // set to true if you want ellipsis to update on window resize. Default is false
                });
            }
        };

        var init = function () {
            var herospaceAddon = function($element) {
                var enddateString = $element.attr('data-enddate').split(' ');
                var enddateFirstHalf = enddateString[0].split('-');
                var enddateSecondHalf = enddateString[1].split(':');
                var enddate = Math.floor(
                    new Date(
                        parseInt(enddateFirstHalf[0]),
                        parseInt(enddateFirstHalf[1]) - 1,
                        parseInt(enddateFirstHalf[2]),
                        parseInt(enddateSecondHalf[0]),
                        parseInt(enddateSecondHalf[1]),
                        parseInt(enddateSecondHalf[2])
                    ).getTime() / 1000
                );

                if (!enddate) {
                    enddate = Math.floor(new Date().getTime() / 1000);
                }

                var dateend = enddate;

                var delta = dateend - currentTime;

                // calculate (and subtract) whole weeks
                var weeks = Math.floor(delta / 604800);
                delta -= weeks * 604800;

                // calculate (and subtract) whole days
                var days = Math.floor(delta / 86400);
                delta -= days * 86400;

                // calculate (and subtract) whole hours
                var hours = Math.floor(delta / 3600) % 24;
                delta -= hours * 3600;

                if (0 > dateend - currentTime) {
                    hours = 0;
                    days = 0;
                    weeks = 0;
                }

                if (0 > hours) {
                    hours = 0;
                }

                if (0 > days) {
                    days = 0;
                }

                if (0 > weeks) {
                    weeks = 0;
                }

                var $herospace = $('.herospace2-basic .hero2-content div.h1');

                if (0 < $herospace.length) {
                    var regex = /\d ((\bWoche\b)|(\bWochen\b)|(\bTag\b)|(\bTage\b)|(\bStunde\b)|(\bStunden\b))/;

                    var content = $herospace.text();

                    var result = regex.test(content);

                    if (result) {
                        var match = regex.exec(content);

                        var resultTime = '';

                        if (0 < weeks) {
                            if (1 < weeks) {
                                resultTime = weeks + ' Wochen';
                            } else {
                                resultTime = weeks + ' Woche';
                            }
                        } else if (0 < days) {
                            if (1 < days) {
                                resultTime = days + ' Tage';
                            } else {
                                resultTime = days + ' Tag';
                            }
                        } else {
                            if (1 < hours) {
                                resultTime = hours + ' Stunden';
                            } else if (0 < hours) {
                                resultTime = hours + ' Stunde';
                            } else {
                                resultTime = hours + ' Stunden';
                            }
                        }

                        var newText = content.replace(match[0], resultTime);

                        $herospace.html(newText);
                    }
                }
            };

            var animateItems = function($element) {
                $element.find('.item').addClass('animated');
                $element.addClass('initrdy');

                var enddateString = $element.attr('data-enddate').split(' ');
                var enddateFirstHalf = enddateString[0].split('-');
                var enddateSecondHalf = enddateString[1].split(':');
                var enddate = Math.floor(new Date(parseInt(enddateFirstHalf[0]), parseInt(enddateFirstHalf[1]) - 1, parseInt(enddateFirstHalf[2]), parseInt(enddateSecondHalf[0]), parseInt(enddateSecondHalf[1]), parseInt(enddateSecondHalf[2])).getTime() / 1000);
                var dateend = enddate;

                var delta = dateend - currentTime;

                // calculate (and subtract) whole weeks
                var weeks = Math.floor(delta / 604800);
                delta -= weeks * 604800;

                // calculate (and subtract) whole days
                var days = Math.floor(delta / 86400);
                delta -= days * 86400;

                // calculate (and subtract) whole hours
                var hours = Math.floor(delta / 3600) % 24;
                delta -= hours * 3600;

                if (0 > dateend - currentTime) {
                    hours = 0;
                    days = 0;
                    weeks = 0;
                }

                if (0 > hours) {
                    hours = 0;
                }

                if (0 > days) {
                    days = 0;
                }

                if (0 > weeks) {
                    weeks = 0;
                }

                $element.find('[data-autocount]').each(function(i) {
                    var shouldCount = 'true' === $(this).attr('data-autocount');
                    var end = $(this).attr('data-max');

                    switch (i) {
                    case 0:
                        end = weeks;
                        break;
                    case 1:
                        end = days;
                        break;
                    case 2:
                        end = hours;
                        break;
                    }
                    var start = end + 15;

                    var countFn = function (item, count, max) {
                        item.html(count - 1);

                        count = count - 1;

                        if (count > max) {
                            setTimeout(function(item2, count2, max2) {
                                countFn(item2, count2, max2);
                            }.bind(null, item, count, max), 40);
                        }
                    };

                    if (shouldCount) {
                        countFn($(this), start, end);
                    }
                    else {
                        $(this).text(end);
                    }
                });
            };

            var scrollTop = 0;

            $(window).scroll(function() {
                scrollTop = $(this).scrollTop();
                $('.countdown').each(function() {
                    if ($(this).hasClass('initrdy')) {
                        return;
                    }

                    if (!'true' === $(this).attr('data-animate-onscroll')) {
                        return;
                    }

                    if (scrollTop + 800 > $(this).offset().top || 0 > $(this).offset().top - 800) {
                        animateItems($(this));
                    }
                });
            });

            $('.countdown').each(function() {

                herospaceAddon($(this));

                if ($(this).hasClass('initrdy')) {
                    return;
                }

                if ('true' === $(this).attr('data-animate-onscroll')) {
                    return;
                }

                animateItems($(this));
            });

            // animate all items which are already in the viewport
            $('.countdown').each(function() {
                if ($(this).hasClass('initrdy')) {
                    return;
                }

                if (!'true' === $(this).attr('data-animate-onscroll')) {
                    return;
                }

                if ($(this).offset().top <= $(window).height()) {
                    animateItems($(this));
                }
            });


            vfCountDownDesktop();
            vfCountDownMobile();

            $(window).resize(function() {

                vfCountDownDesktop();
                vfCountDownMobile();

            });
        };

        var updateDate = function(asset) {
            if (!currentTime) {
                $.ajax({
                    type: 'GET',
                    url: asset,
                    success: function(data, textStatus, request){
                        var date = new Date(request.getResponseHeader('date'));

                        if (!request.getResponseHeader('date')) {
                            date = new Date();
                        }

                        currentTime = Math.floor(date.getTime() / 1000);
                        init();
                    }
                });
            }
        };

        $('.countdown').each(function() {
            if (0 < $(this).attr('data-pixelasset').length) {
                updateDate($(this).attr('data-pixelasset'));
            }
        });

        var adjustImageDimensions = function() {
            // Replicating background-size: cover property on img elements
            $('.mod-countdown .img-wrap').each(function() {

                // Setting the size
                var imgWrapHeight = $(this).height(),
                    imgWrapWidth = $(this).width(),
                    img = $(this).find('img.only-lrg'),
                    initImgHeight = img.height(),
                    initImgWidth = img.width(),
                    containerRatio = imgWrapWidth / imgWrapHeight,
                    imgRatio = initImgWidth / initImgHeight;

                if (0 < initImgHeight && 0 < initImgWidth) {

                    // Checking if it's Portrait or Landscape orientation
                    if (containerRatio > imgRatio) {
                        img.addClass('ww').removeClass('wh');
                    } else {
                        img.addClass('wh').removeClass('ww');
                    }

                    // Setting the offset
                    var newImgHeight = img.height(),
                        newImgWidth = img.width(),
                        halfHeight = 0,
                        halfWidth = 0;

                    // Checking if it's Portrait or Landscape orientation
                    if (containerRatio > imgRatio) {
                        halfHeight = (newImgHeight - imgWrapHeight) / 2;
                        halfWidth = (imgWrapWidth - newImgWidth) / 2;
                        img.css({marginTop: '-' + halfHeight + 'px', marginLeft: 0});
                    } else {
                        halfHeight = (imgWrapHeight - newImgHeight) / 2;
                        halfWidth = (newImgWidth - imgWrapWidth) / 2;
                        img.css({marginLeft: '-' + halfWidth + 'px', marginTop: 0});
                    }
                }
            });
        };

        adjustImageDimensions();

        $(window).resize(adjustImageDimensions);

    });
})();
